import styled from 'styled-components'
import { assetsPath, isSystemUS, translate } from '../services/tools'
import {
  Page,
  Header,
  Title,
  TitleText,
  TitleImg,
  TextInBold,
  ItalicText,
  MainContainer,
  BoldTextContainer,
  ContentImg,
  TitleTextContainer,
  Text,
  Texts,
} from './commun'
import { Summary, ItineraryMap } from './commun'
import { useSelector } from 'react-redux'
import { formatDate, navitiaDateToDate } from '../utils/tools'

const SectionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`

const WalkSection = styled.div``

const MapContent = styled.div`
  padding-left: 10px;
`

const Section = props => {
  const { section, journey } = props
  const { configApp } = useSelector(state => state.app)

  const libelle =
    section.index === 0
      ? translate('departure', false)
      : section.index === journey.sections.length - 1
      ? translate('arrival', false)
      : translate('connection', false, { key: 'number', value: section.indexWalk })

  return (
    <WalkSection>
      {configApp.new_print_design ? <ItalicText>{libelle}</ItalicText> : <TextInBold>{libelle}</TextInBold>}
      <ItineraryMap size="small" {...props} />
    </WalkSection>
  )
}

const PrintItineraryMapWalk = props => {
  const { datas, page, configApp } = props
  const language = useSelector(state => state.app.language)
  const { component, journey } = datas
  const now = new Date()
  const date = formatDate(now, isSystemUS(language) ? 'm/d/y' : 'd/m/y')
  const hours = formatDate(now, isSystemUS(language) ? 'h:m a' : 'h:m')
  const journeyDate = formatDate(navitiaDateToDate(journey.departure_date_time), 'full-with-year', language)

  const sections = journey.sections
    .map((s, i) => ({ ...s, index: i }))
    .filter(
      s =>
        (s.type === 'street_network' && ['walking', 'bike'].includes(s.mode)) ||
        (s.type === 'transfer' && s.transfer_type === 'walking' && s.duration > 0),
    )
    .map((s, i) => ({ ...s, indexWalk: i }))

  if (sections.length === 0 || (sections.length === 1 && sections.length === journey.sections.length)) {
    return null
  }

  return (
    <Page>
      <Header>
        <Title>
          {configApp.new_print_design ? (
            <>
              <TitleTextContainer>
                <TitleText className="lc-print-title-text">{translate('print-page-title')}</TitleText>
                <Text className="journey-date">
                  {translate('journey-date', false, { key: 'date', value: journeyDate })}
                </Text>
              </TitleTextContainer>
              <TitleImg className="lc-print-title-image" image="block-logo" />
            </>
          ) : (
            <>
              <TitleImg src={assetsPath('/assets/images/menu/route-calculation.svg')} />
              <TitleText>{translate('print-page-title')}</TitleText>
            </>
          )}
        </Title>
      </Header>
      {configApp.new_print_design ? (
        <MainContainer>
          {page.summary && <Summary component={component} />}
          <MapContent>
            <BoldTextContainer>
              <ContentImg className="lc-print-title-image" image="picto-zoom" />
              <TextInBold>{translate('print-page-zoom-walking-sections')}</TextInBold>
            </BoldTextContainer>
            <SectionsContainer>
              {sections.map((section, index) => (
                <Section
                  key={`section-${index}`}
                  section={section}
                  sections={sections}
                  component={component}
                  journey={journey}
                  configApp={configApp}
                />
              ))}
            </SectionsContainer>
          </MapContent>
          <Texts>
            <Text>
              {translate('print-page-date', false, { key: 'date', value: date }, { key: 'hours', value: hours })}
            </Text>
            <Text dangerouslySetInnerHTML={{ __html: translate('print-page-check-website') }}></Text>
          </Texts>
        </MainContainer>
      ) : (
        <>
          {page.summary && <Summary component={component} />}
          <MapContent>
            <TextInBold>{translate('print-page-zoom-walking-sections')}</TextInBold>
            <SectionsContainer>
              {sections.map((section, index) => (
                <Section
                  key={`section-${index}`}
                  section={section}
                  sections={sections}
                  component={component}
                  journey={journey}
                  configApp={configApp}
                />
              ))}
            </SectionsContainer>
          </MapContent>
        </>
      )}
    </Page>
  )
}

export default PrintItineraryMapWalk

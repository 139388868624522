export default (state = {}, action) => {
  switch (action.type) {
    case 'TOGGLE_MODAL_GENERIC': {
      let newState = {
        ...state,
      }

      if (!state.modalFile || state.modalFile !== action.name) {
        newState.modalFile = action.name
        newState.showModalGeneric = true
      } else {
        newState.showModalGeneric = !state.showModalGeneric
      }

      return newState
    }
    default:
      return state
  }
}

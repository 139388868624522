export default (
  state = {
    inputValue: '',
    inputStartValue: '',
    inputEndValue: '',
    inputStartObj: {
      empty: true,
      swap: false,
      name: '',
    },
    inputEndObj: {
      empty: true,
      swap: false,
      name: '',
    },
    inputItems: [],
    inputStartItems: [],
    inputEndItems: [],
    goToValid: false,
    currentStationDepartureIndex: 0,
    currentStationArrivalIndex: 0,
    datesPanel: false,
    modesPanel: false,
    radiusPanel: true,
    walkingSpeedsPanel: false,
    bikePanel: false,
    travelSolutionsPanel: false,
    linesRegionsPanels: [],
    ddlsPanels: [],
    displayError: [],
    linesSchedules: [],
  },
  action,
) => {
  switch (action.type) {
    case 'ON_INPUT_VALUE_CHANGE':
      return {
        ...state,
        inputValue: action.value,
      }

    case 'ON_INPUTS_SWAP':
      return {
        ...state,
        inputStartObj: {
          swap: true,
          ...action.to,
        },
        inputEndObj: {
          swap: true,
          ...action.from,
        },
        inputStartValue: action.to.label ? action.to.label : action.to.name,
        inputEndValue: action.from.label ? action.from.label : action.from.name,
      }

    case 'ON_START_INPUT_VALUE_CHANGE': {
      const startObj = {
        empty: action.value.name.length <= 0,
        swap: false,
        ...action.value,
      }

      return {
        ...state,
        inputStartObj: startObj,
        inputStartValue: action.value.label ? action.value.label : action.value.name,
      }
    }
    case 'ON_END_INPUT_VALUE_CHANGE': {
      const endObj = {
        empty: action.value.name.length <= 0,
        swap: false,
        ...action.value,
      }

      return {
        ...state,
        inputEndObj: endObj,
        inputEndValue: action.value.label ? action.value.label : action.value.name,
      }
    }
    case 'ON_INPUT_ITEMS_CHANGE':
      return {
        ...state,
        inputItems: action.items,
      }

    case 'ON_START_INPUT_ITEMS_CHANGE':
      return {
        ...state,
        inputStartItems: action.items,
      }

    case 'ON_END_INPUT_ITEMS_CHANGE':
      return {
        ...state,
        inputEndItems: action.items,
      }

    case 'SET_PLACE_CLICKED':
      return {
        ...state,
        placeClicked: action.place,
      }

    case 'SET_THEMATIC_PLACES':
      return {
        ...state,
        thematicPlaces: action.thematicPlaces,
      }

    case 'SET_THEMATIC_LIB_MARKERS':
      return {
        ...state,
        libraryThematicMarkers: action.libraryThematicMarkers,
      }

    case 'SET_THEMATIC_LIB_DISPLAY':
      return {
        ...state,
        libraryThematicDisplay: action.libraryThematicDisplay,
      }

    case 'SET_THEMATIC_LIB_PREDICT':
      return {
        ...state,
        libraryThematicPredict: action.libraryThematicPredict,
      }

    case 'SET_OPENED_COLLAPSE':
      return {
        ...state,
        openedCollapse: action.collapse === state.openedCollapse ? false : action.collapse,
      }

    case 'SET_DISRUPTIONS':
      return {
        ...state,
        disruptions: action.disruptions,
      }

    case 'SET_DISRUPTION_IN_LINE':
      return {
        ...state,
        disruptionsInLine: action.disruptionsInLine,
      }

    case 'SET_GEOLOCATION_ERROR': {
      if (!action.error) {
        return {
          ...state,
          geolocationError: null,
        }
      }

      const geolocationError = {
        code: action.error.code,
      }

      switch (action.error.code) {
        case action.error.PERMISSION_DENIED:
          geolocationError.message = 'La géolocalisation est désactivée sur votre appareil et/ou votre navigateur.'
          break
        case action.error.POSITION_UNAVAILABLE:
          geolocationError.message = 'Impossible de récupérer votre position actuellement.'
          break
        case action.error.TIMEOUT:
          geolocationError.message = 'La demande de géolocalisation a expiré.'
          break
        case action.error.UNKNOWN_ERROR:
          geolocationError.message = 'Une erreur inconnue est survenue.'
          break
        default:
          geolocationError.message = 'Une erreur inconnue est survenue.'
          break
      }

      return {
        ...state,
        geolocationError,
      }
    }
    case 'SET_LINE_INFORMATION':
      return {
        ...state,
        lineInformation: action.info,
      }

    case 'SET_GO_TO_ROUTE_CALC':
      return {
        ...state,
        goToValid: action.valid,
      }

    case 'RESET_STATION_INDEX':
      return {
        ...state,
        currentStationDepartureIndex: 0,
        currentStationArrivalIndex: 0,
      }

    case 'DECREMENT_STATION_INDEX':
      switch (action.direction) {
        case 'departure':
          return {
            ...state,
            currentStationDepartureIndex:
              state.currentStationDepartureIndex > 0 ? state.currentStationDepartureIndex - 1 : 0,
          }

        case 'arrival':
          return {
            ...state,
            currentStationArrivalIndex: state.currentStationArrivalIndex > 0 ? state.currentStationArrivalIndex - 1 : 0,
          }

        default:
          return state
      }

    case 'INCREMENT_STATION_INDEX':
      switch (action.direction) {
        case 'departure':
          return {
            ...state,
            currentStationDepartureIndex:
              state.currentStationDepartureIndex < 6 ? state.currentStationDepartureIndex + 1 : 6,
          }

        case 'arrival':
          return {
            ...state,
            currentStationArrivalIndex: state.currentStationArrivalIndex < 6 ? state.currentStationArrivalIndex + 1 : 6,
          }

        default:
          return state
      }

    case 'SET_ROUTE_CALC_DATES_PANEL':
      return {
        ...state,
        datesPanel: !action.datesPanel,
      }

    case 'SET_ROUTE_CALC_MODES_PANEL':
      return {
        ...state,
        modesPanel: !action.modesPanel,
      }

    case 'SET_ROUTE_CALC_TRAVEL_SOLUTIONS_PANEL':
      return {
        ...state,
        travelSolutionsPanel: !action.travelSolutionsPanel,
      }

    case 'SET_AROUND_RADIUS_PANEL':
      return {
        ...state,
        radiusPanel: !action.radiusPanel,
      }

    case 'SET_ROUTE_CALC_WALKINGSPEEDS_PANEL':
      return {
        ...state,
        walkingSpeedsPanel: !action.walkingSpeedsPanel,
      }

    case 'SET_ROUTE_CALC_BIKE_PANEL':
      return {
        ...state,
        bikePanel: !action.bikePanel,
      }

    case 'SET_MOBILITY_OBJECT':
      return {
        ...state,
        mobilityObject: action.mobilityObject,
      }

    case 'SET_LINESREGIONS_PANEL':
      return {
        ...state,
        linesRegionsPanels: [
          ...state.linesRegionsPanels.filter(lr => lr.code !== action.code).map(lr => ({ code: lr.code, open: false })),
          { code: action.code, open: action.open },
        ],
      }

    case 'SET_DDLS_PANEL':
      return {
        ...state,
        ddlsPanels: [
          ...state.ddlsPanels.filter(dl => dl.code !== action.code).map(dl => ({ code: dl.code, open: false })),
          { code: action.code, open: action.open },
        ],
      }

    case 'CLEAN_LINESREGIONS':
      return {
        ...state,
        linesRegionsPanels: [],
        ddlsPanels: [],
      }

    case 'SET_FAVORITE_LINE':
      return {
        ...state,
        favoriteLine: action.line,
      }

    case 'SET_FAVORITE_STOP':
      return {
        ...state,
        favoriteStop: action.stop,
      }

    case 'SET_DISPLAY_ERROR': {
      const newDisplayError = state.displayError

      if (!newDisplayError.find(e => e.id === action.id)) {
        newDisplayError.push({ id: action.id, type: action.typeError, message: action.message })
      }

      return {
        ...state,
        displayError: newDisplayError,
      }
    }
    case 'SET_HIDE_DISPLAY_ERROR':
      return {
        ...state,
        displayError: [...state.displayError.filter(e => e.id !== action.id)],
      }

    case 'SET_DISPLAY_TIMETABLE_DATA':
      return {
        ...state,
        timetableData: action.data.timetableData,
        timetableHours: action.data.timetableHours,
      }

    case 'SET_LINES_SCHEDULES':
      return { ...state, linesSchedules: action.schedules }
    default:
      return state
  }
}

import axios from '../../middlewares/axios'
import L from 'leaflet'
import { envVarToBool, translate } from '../../services/tools'
import { navitiaDateToDate } from '../tools'
import pointToLineDistance from '@turf/point-to-line-distance'

/**
 * Create a google coord
 * @param lat
 * @param lng
 */
export const createCoords = (lat, lng) => {
  return [lat, lng]
}

/**
 *
 * @returns {Promise<string|coords|{lat, lon}|Coordinates|string>}
 */
export const geolocInput = async () => {
  const position = await getCurrentPosition({
    timeout: 3000,
    enableHighAccuracy: true,
  })

  const { longitude, latitude } = position.coords

  return substringCoords({
    lon: longitude,
    lat: latitude,
  })
}

/**
 * Return lines in a selected town
 * @param town
 * @returns {Promise<void>}
 */
export const getLinesInTown = async (component, town) => {
  const { linesModes } = component.props

  const response = await axios.get('/api/data-in-town?insee=' + town.insee).catch(e => {
    const error = e.response && e.response.data ? e.response.data.id : e

    console.warn(error)
  })

  const lines = response.data.shift()
  // Retrieve transport pois
  const pois = lines.pop()
  const groups = groupLinesByMode(unique(lines, 'id'), linesModes, 'mode') // Make the array unique by the lines ID and order
  const dataPlaces = response.data.shift()

  // If JD only keep key in places ref for them
  const places = Object.keys(dataPlaces)
    .sort(function (a, b) {
      return a.localeCompare(b)
    })
    .reduce(function (sorted, key) {
      sorted[key] = dataPlaces[key]
      return sorted
    }, {})

  component.setState({
    town,
    groups,
    pois,
    places,
    inputAroundValue: town.name,
    inputAroundGoToRoute: false,
  })
}

// returns closer stopId of a point from a StopsList
export const getCloserStopIdFromStopsList = (pin, stopsList) => {
  // TODO place params for CCVE
  let currentStop = null

  for (const stop of stopsList) {
    const position = new L.LatLng(stop.coord.lat, stop.coord.lon)

    stop.distance = getDistanceBetweenMarkers(pin, position)

    if (!currentStop || stop.distance < currentStop.distance) {
      currentStop = stop
    }
  }

  return currentStop ? currentStop.id : false
}

/**
 * Return position geolocated
 * @param options
 * @returns {Promise<any>}
 */
export const getCurrentPosition = (options = {}) => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options)
  })
}

export const getDestinations = timetableData => {
  // returns secondary destinations from Data     if html = true, returns html
  let otherdirections = []
  let htmlArray = []
  let first = true

  if (timetableData) {
    // Check if morning, afternoon and evening are all empty
    for (const period in timetableData) {
      if (Object.prototype.hasOwnProperty.call(timetableData, period) && timetableData[period]) {
        for (let timetableDataContent of timetableData[period]) {
          for (const direction of timetableDataContent.directions) {
            if (direction) {
              // If it is an other direction than the main one (without town name)
              if (otherdirections.indexOf(direction) === -1) {
                otherdirections.push(direction) // if this direction is not in otherdirections, add it
                first && htmlArray.push(<div key={direction}>{translate('timetable-other-destination')} : </div>)

                if (!first) {
                  htmlArray[0] = (
                    <div className="lc-otherdirectionsContent">{translate('timetable-others-destinations')} : </div>
                  )
                }

                first = false
                htmlArray.push(
                  <div
                    key={direction + '_' + (Object.keys(otherdirections).length + 96)}
                    className="lc-otherdirectionsContent">
                    <span className="lc-otherdirectionsContentLetter">
                      {String.fromCharCode(Object.keys(otherdirections).length + 96)}
                    </span>{' '}
                    {' : ' + direction}{' '}
                  </div>,
                )
              }
            }
          }
        }
      }
    }
  }

  return htmlArray.length === 0 ? null : htmlArray
}

/**
 * Calcul distance between two points
 * @returns {number}
 * @param position
 * @param marker
 */
export const getDistanceBetweenMarkers = (position, marker) => {
  const R = 6371
  const distLat = ((position.lat - marker.lat) * Math.PI) / 180
  const distLon = ((position.lng - marker.lng) * Math.PI) / 180

  const a =
    Math.sin(distLat / 2) ** 2 +
    Math.cos((marker.lat * Math.PI) / 180) *
      Math.cos((position.lat * Math.PI) / 180) *
      Math.sin(distLon / 2) *
      Math.sin(distLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return Math.round(R * c * 1000)
}

export const getColorGroup = (modes, group) => {
  const hex = modes.find(m => m.name === group).color || null

  if (hex) {
    return { color: hex }
  } else {
    return null
  }
}

/**
 * Group lines by mode, then sort them by property
 * @param lines
 * @param property
 */
export const groupLinesByMode = (lines, linesModes) => {
  const groups = {}

  for (let i = 0; i < linesModes.length; i++) {
    const mode = linesModes[i]
    let linesInMode = lines
      .filter(l => mode.modes.includes(l.mode))
      .sort((a, b) => {
        return a.position - b.position
      })

    // We only keep groups with lines
    if (linesInMode.length > 0) {
      groups[mode.name] = linesInMode
    }
  }

  return groups
}

/**
 * Return if value is real coordinates
 * @param coord
 * @returns {boolean}
 */
export const isCoords = coord => {
  const lon = coord.split(';')[0]
  const lat = coord.split(';')[1]

  return !!(!isNaN(lon) && isBetween(lon, -180, 180) && !isNaN(lat) && isBetween(lat, -90, 90))
}

/**
 * Sort an array by a property
 * @param array
 * @param property
 * @returns Array
 */
export const sortBy = (array, property) =>
  array.sort((a, b) => +(a[property] > b[property]) || +(a[property] === b[property]) - 1)

/** Sort an array of lines ids per line position in lines array
 * Sort
 * @param {array} linesIds
 * @param {array} lines
 * @returns
 */
export const sortLinesPerPosition = (linesIds, lines) => {
  return linesIds.sort((a, b) => {
    const [modeA, codeA] = a.split('~~')
    const [modeB, codeB] = b.split('~~')
    const lineA = lines.find(l => {
      return modeA && codeA
        ? String(codeA).toLowerCase() === String(l.code).toLowerCase() && l.cat === modeA
        : l.id === a
    })
    const lineB = lines.find(l => {
      return modeB && codeB
        ? String(codeB).toLowerCase() === String(l.code).toLowerCase() && l.cat === modeB
        : l.id === b
    })

    if (lineA?.position > lineB?.position) {
      return -1
    } else {
      return 1
    }
  })
}

/**
 * Sort an array by a porperty and alphabetic order
 * @param array
 * @param property
 */
export const sortAlphabetic = (array, property) => array.sort((a, b) => a[property].localeCompare(b[property]))

export const sortAlphanumericWithStartLetter = (array, property, letter) => {
  // Sort S lines
  let start = -1
  let end = -1

  // Find the start and end of S lines
  for (const line of array) {
    if (line.code.startsWith(letter) && start === -1) {
      start = array.indexOf(line)
    } else if (line.code.startsWith(letter)) {
      end = array.indexOf(line)
    }
  }

  const sLines = array.splice(start, end + 1) // +1 cause "end" slice is exclude
  const reA = /[^a-zA-Z]/g
  const reN = /[^0-9]/g

  // Sort alphanumeric
  sLines.sort((a, b) => {
    const aA = a[property].replace(reA, '')
    const bA = b[property].replace(reA, '')

    if (aA === bA) {
      const aN = parseInt(a[property].replace(reN, ''), 10)
      const bN = parseInt(b[property].replace(reN, ''), 10)

      return aN === bN ? 0 : aN > bN ? 1 : -1
    } else {
      return aA > bA ? 1 : -1
    }
  })

  // Put S lines again in our lines array
  array.splice(start, 0, ...sLines)
}

/**
 * Return coord with personalize length for url
 * @param latlng
 * @returns {string}
 */
export const substringCoords = latlng => {
  if (!(latlng instanceof L.LatLng)) {
    latlng = new L.LatLng(latlng.lat, latlng.lon)
  }

  return Number(latlng.lng).toFixed(4) + ';' + Number(latlng.lat).toFixed(4)
}

export const timetableDataIsEmpty = timetableData => {
  let timetableEmpty = true

  if (timetableData) {
    // Check if morning, afternoon and evening are all empty
    for (const period in timetableData) {
      if (Object.prototype.hasOwnProperty.call(timetableData, period) && timetableData[period]) {
        if (timetableData[period].length > 0) {
          timetableEmpty = false
        }
      }
    }
  }

  return timetableEmpty
}

/**
 * Remove duplicates entries of an Array by a specifiq property
 * @param array
 * @param property
 * @returns Array
 */
export const unique = (array, property) =>
  array.filter((e, i) => array.findIndex(a => a[property] === e[property]) === i)

/**
 * Create an object from the url
 * @param url
 */
export const updateURLState = url => {
  const dataUrl = {}

  for (let data of url.search.substr(1).split('&')) {
    const value = data.split('=')

    dataUrl[value.shift()] = decodeURIComponent(value.shift())
  }

  return dataUrl
}

export const buildTimetableDatas = (data, line) => {
  let timetables, hours

  const nightLines = process.env.REACT_APP_NIGHT_LINES
    ? JSON.parse(process.env.REACT_APP_NIGHT_LINES)
    : { departure: '00', lines: [] }

  const isNightline = nightLines.lines.includes(line.id)
  const departure = +nightLines.departure

  timetables = {
    morning: [],
    afternoon: [],
    evening: [],
  }

  hours = {
    morning: Array.from(new Array(8), (_, i) => i + (isNightline ? departure : 4)).map(x => (x >= 24 ? x - 24 : x)),
    afternoon: Array.from(new Array(8), (_, i) => i + (isNightline ? departure + 8 : 12)).map(x =>
      x >= 24 ? x - 24 : x,
    ),
    evening: Array.from(new Array(8), (_, i) => i + (isNightline ? departure + 16 : 20)).map(x =>
      x >= 24 ? x - 24 : x,
    ),
  }

  const firstDateTime = data.length > 0 ? data[0] : false
  const lastDateTime = data.length > 0 ? data[data.length - 1] : false

  if (envVarToBool(process.env.REACT_APP_TIMETABLE_START_CURRENT_HOUR) && firstDateTime) {
    const date = navitiaDateToDate(firstDateTime.date_time)
    const today = new Date()

    if (
      date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate()
    ) {
      const lastDate = navitiaDateToDate(lastDateTime.date_time)
      const lastDateHours = lastDate.getHours() < 4 ? 24 + lastDate.getHours() : lastDate.getHours()
      const firstHour = hours.morning.indexOf(today.getHours()) ? today.getHours() : hours.morning[0]
      const lastHour = lastDateTime ? lastDateHours : isNightline ? departure + 16 + 8 : 20 + 8
      const newHours = []

      for (let h = firstHour; h <= lastHour; h++) {
        if (h > 23) {
          newHours.push(h - 24)
        } else {
          newHours.push(h)
        }
      }

      if (newHours.length < 9) {
        hours.morning = newHours.slice(0)
        hours.afternoon = []
        hours.evening = []
      }

      if (newHours.length > 8 && newHours.length < 17) {
        hours.morning = newHours.slice(0, 8)
        hours.afternoon = newHours.slice(8)
        hours.evening = []
      }

      if (newHours.length > 16) {
        hours.morning = newHours.slice(0, 8)
        hours.afternoon = newHours.slice(8, 15)
        hours.evening = newHours.slice(15)
      }
    }
  }

  for (const key of Object.keys(hours)) {
    for (const h of hours[key]) {
      timetables[key].push(...data.filter(d => parseInt(d.date_time.substring(9, 11)) === parseInt(h)))
    }
  }

  return { timetableData: timetables, timetableHours: hours }
}

export const computeLineOffset = (currentLine, futureLine, override = {}) => {
  try {
    if (currentLine?.type === 'FeatureCollection') {
      currentLine = currentLine.features[0]
    }

    if (futureLine?.type === 'FeatureCollection') {
      futureLine = futureLine.features[0]
    }

    if (
      currentLine?.type === 'Feature' &&
      currentLine?.geometry?.type === 'MultiLineString' &&
      futureLine?.type === 'Feature' &&
      futureLine?.geometry?.type === 'MultiLineString'
    ) {
      let intersections = []

      const lineString = coordinates => {
        return {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: coordinates,
          },
        }
      }

      for (let i = 0; i < currentLine.geometry.coordinates.length; i++) {
        const thisline = lineString(currentLine.geometry.coordinates[i])

        for (let j = 0; j < futureLine.geometry.coordinates.length; j++) {
          if (!(override && override.skip && override.skip.length > 0 && override.skip.includes(`i${i}j${j}`))) {
            const compline = lineString(futureLine.geometry.coordinates[j])
            var currentIn = 0
            let coordsIn = [[]]

            for (let k = 0; k < compline.geometry.coordinates.length; k++) {
              const coord = compline.geometry.coordinates[k]

              if (pointToLineDistance(coord, thisline, { units: 'meters' }) < 50) {
                coordsIn[currentIn].push(coord)
              } else {
                if (coordsIn[currentIn].length > 0) {
                  coordsIn[currentIn + 1] = []
                  currentIn++
                }
              }
            }

            intersections.push(
              ...coordsIn
                .filter(coords => coords.length >= 2) // && length(lineString(coords), { units: "meters" }) > 50
                .map(coords => {
                  const intersecLineString = lineString(coords)
                  let offsetValue = override.offsetValue ? override.offsetValue : 6
                  let smoothFactor = override.smoothFactor ? override.smoothFactor : 1

                  return {
                    ...intersecLineString,
                    properties: {
                      offset: true,
                      offsetValue: offsetValue,
                      smoothFactor: smoothFactor,
                      index: `i${i}j${j}`,
                    },
                  }
                }),
            )
          }
        }
      }

      // console.log(
      //   JSON.stringify({
      //     type: "FeatureCollection",
      //     features: [...intersections],
      //   })
      // );

      return {
        type: 'FeatureCollection',
        features: [...intersections, currentLine],
      }
    } else {
      return {
        type: 'FeatureCollection',
        features: [currentLine],
      }
    }
  } catch (e) {
    console.log('Error in computeLineOffset : ', e)
  }
}

// --------------------------- PRIVATE --------------------------- //

/**
 * check if x is between min and max
 * @param x
 * @param min
 * @param max
 * @returns {boolean}
 */
const isBetween = (x, min, max) => x >= min && x <= max

import { useState, useEffect, useRef } from 'react'

export function useOnScreen(rootMargin = '-150px 0px 0px 0px') {
  const ref = useRef()
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const node = ref.current

    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting)
      },
      {
        rootMargin,
      },
    )

    if (node) {
      observer.observe(node)
    }

    return () => {
      observer.unobserve(node)
    }

    // eslint-disable-next-line
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return [ref, isIntersecting]
}

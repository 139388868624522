import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actionLoginSetData, actionLoginSetToken } from '../actions/login'
import axios from '../middlewares/axios'
import { storageAvailable } from '../services/tools'
import App from './App'
import LogIn from './LogIn'

const { REACT_APP_LOGIN, REACT_APP_PROJECT } = process.env
const loginConfig = REACT_APP_LOGIN ? JSON.parse(REACT_APP_LOGIN) : null

const AppWrapper = ({ options }) => {
  const dispatch = useDispatch()
  const token = useSelector(x => x.login.token)
  const data = useSelector(x => x.login.data)

  let state = 'app'

  if (loginConfig) {
    if (data) state = 'app'
    else if (token) state = 'checkToken'
    else state = 'getToken'
  }

  useEffect(() => {
    let cancelled = false

    const hasLocalStorage = storageAvailable('localStorage')

    const checkToken = async () => {
      const result = await axios(`/api/checkLogin`, { method: 'POST', data: { token } })
      if (cancelled) return

      if (result && result.data.status) {
        if (result.data.status === 'success') {
          axios.transformConfig.headers['X-Auth-Token'] = token
          if (hasLocalStorage) {
            localStorage.setItem(`token_${REACT_APP_PROJECT}`, token)
          }
          dispatch(actionLoginSetData(result.data.data))
        } else {
          dispatch(actionLoginSetToken(null))
          if (hasLocalStorage) {
            localStorage.removeItem(`token_${REACT_APP_PROJECT}`)
          }
        }
      }
    }

    if (state === 'getToken' && hasLocalStorage) {
      const storedToken = localStorage.getItem(`token_${REACT_APP_PROJECT}`)
      if (storedToken) {
        dispatch(actionLoginSetToken(storedToken))
      }
    } else if (state === 'checkToken') {
      checkToken()
    }

    return () => {
      cancelled = true
    }
  }, [state, token, dispatch])

  switch (state) {
    case 'app':
      return <App options={options} />

    case 'getToken':
      return <LogIn />

    default:
      return null
  }
}

export default AppWrapper

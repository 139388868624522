import { detect } from 'detect-browser'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import outdated from './outdated'
import { appStore } from './store'
import axios from './middlewares/axios'
import { isLocalOrDev } from './services/tools'
import AppWrapper from './components/AppWrapper'
import history from './history'

const browser = detect()
const element = '#lcmap'
const { search } = history.location

/**
 * Render whole app
 * @param {*} options
 * @param {*} element
 */
const lcmap = async options => {
  // IE Error message
  if (browser.name === 'ie') {
    outdated(browser, element)
    throw new Error('Internet Explorer unsupported')
  }

  if (process.env.REACT_APP_LIBRARY_URL) {
    if (!isLocalOrDev()) {
      let host = document.domain

      if (host.startsWith('www.')) {
        host = host.replace('www.', '')
      }

      const response = await axios.get(`/api/validate-host?host=${host}`)

      if (!response.data.valid) {
        throw new Error("You're not authorized to display this content")
      }
    }

    // Throw an error if there is no DOM element
    if (!document.querySelector(element)) {
      throw new Error("No DOM element with id 'lcmap' was found")
    }
  } else {
    // Override default style if we are not using the app as a library
    document.body.style.margin = 0
    // Temporary fix. TODO : change all index.html with a root ID of lcmap
    document.querySelector('#app') && document.querySelector('#app').setAttribute('id', 'lcmap')
    document.querySelector(element).style.height = 'calc(var(--vh, 1vh) * 100)'
  }

  console.log('options', options)
  let node = document.querySelector(element)

  // Avoid the lib to mount multiple times
  // WARNING : This might have to be on the OBSERVE_LIB var too but it should be an optimization anyways
  // tours-filbleu could potentially be affected by that part
  if (node.dataset.mounted) {
    console.warn('Lib already mounted, return.')
    return null
  }
  node.dataset.mounted = 'mounted'

  ReactDOM.render(
    <Provider store={appStore}>
      <AppWrapper options={options} />
    </Provider>,
    node,
  )

  // Display the outdated message only if we aren't printing something
  !search.includes('print=true') && outdated(browser, element)

  if (process.env.REACT_APP_OBSERVE_LIB) {
    const observer = new MutationObserver(() => {
      if (node && !node.isConnected) {
        console.info('Lib node is not connected. Unmount lib component')
        ReactDOM.unmountComponentAtNode(node)
        node = null
        observer.disconnect()
      }
    })
    observer.observe(document.body, {
      subtree: true,
      childList: true,
    })
  }
}

// If we are not in library mode, just render the app as it is
if (!process.env.REACT_APP_LIBRARY_URL) {
  lcmap()
}

// Disable outlines on mouse interaction only !
;(function (doc) {
  const style = doc.createElement('style')

  doc.getElementsByTagName('head')[0].appendChild(style)

  doc.addEventListener('mousedown', function () {
    style.innerHTML = '#lcmap *:focus{outline:0}'
  })

  doc.addEventListener('keydown', function (e) {
    if (e.key === 'Tab') {
      style.innerHTML = ''
    }
  })
})(document)

export default lcmap

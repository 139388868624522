export default class StopInterface {
  constructor(stop, line, project) {
    switch (project) {
      case 'bordeaux-tbm':
      case 'bordeaux-tbm-azure':
        this.id = stop.id
        this.name = stop.fullLabel
        this.coord = {
          lat: stop.latitude,
          lon: stop.longitude,
        }
        this.pmr = false
        this.terminus = stop.terminus
        this.lines = [line]
        break
      default:
        break
    }
  }
}

import { createaroundPin, debug, isInFrame, checkMoreDataReceived, updatePopupPosition } from './tools'
import { appStore } from '../store'
import { buildCustomMarkers, buildLinePath } from './map'
import {
  actionSetCustomMarkers,
  actionAddCustomMarkers,
  actionSetCenter,
  actionSetZoom,
  actionSetAroundPin,
  actionSetLineSelectedPath,
} from '../actions/map'
import { actionSetSliderUrlLeft, actionSetSliderUrlRight } from '../actions/network'
import { actionBuildCustomLines, actionBuildTransportPlaces, actionBuildCustomMarker } from '../actions/withRedux'
import history from '../history'
import { actionSetConfig, actionAddStops, actionAddLine, actionSetAppTracking } from '../actions/app'
import ThematicInterface from '../interfaces/ThematicInterface'
import LineInterface from '../interfaces/LineInterface'
import CustomPopupInterface from '../interfaces/CustomPopupInterface'
import { actionSetThematicLibDisplay, actionSetThematicLibPredict, actionSetThematicPlaces } from '../actions/board'
import { fitBounds } from '../utils/leaflet/map'
import { isCoords } from '../utils/leaflet/tools'
import L from 'leaflet'
import { luminance } from 'luminance-js'
import axios from '../middlewares/axios'
import JourneysInterface from '../interfaces/JourneysInterface'

const { REACT_APP_PROJECT } = process.env

/**
 * Use postMessage API to pass data through the iframe / library direclty to the main website
 * @see https://developer.mozilla.org/fr/docs/Web/API/Window/postMessage
 * @param {Object} data All data we wanna pass through the main website for this message
 */
export const message = data => {
  // Retreive the correct DOM Element to post message
  const element = isInFrame() ? window.parent : window
  const documentReferer = appStore.getState().app.documentReferer

  // Retrieve the correct target origin for our message
  const targetOrigin =
    process.env.REACT_APP_ENV === 'local'
      ? '*'
      : process.env.REACT_APP_LIBRARY_URL
      ? window.document.location.origin
      : isInFrame()
      ? documentReferer // document.referrer // document.location.ancestorOrigins[0]
      : process.env.REACT_APP_API_PROXY_URL || window.location.origin

  // Post message, or warn in console if something went wrong
  try {
    element.postMessage({ source: 'lcmap', ...data }, targetOrigin)
    // Debug for dev & local
    debug({ message: 'message sent with', data }, 'info', 'post message')
  } catch (e) {
    debug({ message: 'Error while sending ', data }, 'error', 'error post message')
    throw new Error("Oops, une erreur est survenue sur l'envoi de données postMessage !")
  }
}

export const handleReceivedMessage = async event => {
  const geolocOptions = process.env.REACT_APP_PIN_GEOLOC_OPTIONS

  if (event.data.source === 'lcmap-in') {
    if (event.data.focus) {
      switch (event.data.focus) {
        case 'inputStart':
          document.querySelector('#inputStart').focus()
          break
        case 'inputEnd':
          document.querySelector('#inputEnd').focus()
          break
        default:
          break
      }
    }

    if (event.data.route) {
      history.push((!event.data.route.startsWith('/') ? '/' : '') + event.data.route)
    }

    if (event.data.lines !== undefined) {
      appStore.dispatch(actionBuildCustomLines(event.data.lines))
    }

    if (event.data.markers !== undefined) {
      appStore.dispatch(actionSetCustomMarkers(buildCustomMarkers(event.data.markers)))
    }

    if (event.data.zoom) {
      appStore.dispatch(actionSetZoom(event.data.zoom))
    }

    if (event.data.center) {
      appStore.dispatch(actionSetCenter(event.data.center))
    }

    if (event.data.sliderUrls) {
      if (event.data.sliderUrls.right) {
        appStore.dispatch(actionSetSliderUrlRight(event.data.sliderUrls.right))
      }

      if (event.data.sliderUrls.left) {
        appStore.dispatch(actionSetSliderUrlLeft(event.data.sliderUrls.left))
      }
    }

    if (event.data.config) {
      appStore.dispatch(actionSetConfig(event.data.config))
    }

    if (event.data.geolocation !== undefined && geolocOptions) {
      const geolocation = event.data.geolocation
      const { lat, lng } = geolocation
      const { component } = appStore.getState().app

      if (geolocation && (!lat || !lng)) {
        message({
          warning: 'geolocation need a lat and a lng',
        })
      } else {
        component && component.removePinCircle && component.removePinCircle(true)

        if (!geolocation) {
          appStore.dispatch(actionSetAroundPin({}))
        } else if (geolocation) {
          if (isCoords(`${lng};${lat}`)) {
            if (geolocation) {
              // TODO test if coords are in bounds
              const geoloc = createaroundPin([lat, lng], JSON.parse(geolocOptions))

              appStore.dispatch(actionSetAroundPin(geoloc))
              const map = appStore.getState().app?.component?.props?.map
              const { aroundCircles, aroundPin } = map?.props || {}

              if (aroundCircles.length) {
                fitBounds(map, null, -1, geoloc.refCircle[0].leafletElement.getBounds())
              } else if (aroundPin) {
                fitBounds(map, [aroundPin])
              }
            } else {
              message({
                warning: 'geolocation coords out of bounds',
              })
            }
          } else {
            message({
              warning: 'invalid geolocation coords',
            })
          }
        }
      }
    }

    if (event.data.thematics) {
      const component = appStore.getState().app?.component
      const thematic = component.props?.moduleData?.data || null

      if (thematic) {
        const obj = {}
        const data = event.data.thematics.find(t => t.name === thematic)
        const interfacedDatas = []

        const display =
          data.display ||
          appStore.getState().app?.component?.props?.libraryThematicDisplay ||
          appStore.getState().app?.component?.props?.moduleData?.defaultDisplay ||
          null

        const predict =
          data.predict !== undefined
            ? data.predict
            : appStore.getState().app?.component?.props?.libraryThematicPredict || false

        if (data.markers) {
          for (const marker of data.markers) {
            if (appStore.getState().app?.component?.props?.moduleData?.overrideID) {
              const override = appStore.getState().app?.component?.props?.moduleData?.overrideID

              if (marker[override]) {
                marker.id = marker[override]
              }
            }

            if (predict) {
              marker.tau = data.tau
              marker.predict = predict.find(
                p =>
                  String(p[appStore.getState().app?.component?.props?.moduleData?.predictID || 'id']) ===
                  String(marker.id),
              )
            }

            interfacedDatas.push(
              new ThematicInterface({ ...marker, display }, REACT_APP_PROJECT.toLowerCase(), thematic),
            )
          }
        } else {
          const markers = appStore.getState().app?.component?.props?.libraryThematicMarkers || null

          if (markers) {
            for (const marker of markers) {
              if (appStore.getState().app?.component?.props?.moduleData?.overrideID) {
                const override = appStore.getState().app?.component?.props?.moduleData?.overrideID

                if (marker[override]) {
                  marker.id = marker[override]
                }
              }

              if (predict) {
                marker.tau = data.tau
                marker.predict = predict.find(
                  p =>
                    String(p[appStore.getState().app?.component?.props?.moduleData?.predictID || 'id']) ===
                    String(marker.id),
                )
              } else {
                delete marker.predict
              }

              interfacedDatas.push(
                new ThematicInterface({ ...marker, display }, REACT_APP_PROJECT.toLowerCase(), thematic),
              )
            }
          } else {
            message({
              warning: 'no markers detected',
            })
          }
        }

        appStore.dispatch(actionSetThematicLibPredict(predict))
        appStore.dispatch(actionSetThematicLibDisplay(display))
        appStore.dispatch(actionBuildTransportPlaces({ thematic: interfacedDatas }, true))
        obj[thematic] = interfacedDatas
        appStore.dispatch(actionSetThematicPlaces(obj))
        component && component.buildMarkers(true)
      }
    }

    if (event.data.itineraries) {
      const component = appStore.getState().app?.component
      const { map } = component.props
      const itineraries = event?.data?.itineraries
      const journeys = itineraries?.results?.journeys
        ? await JourneysInterface(
            itineraries?.results?.journeys,
            appStore.getState().app.stops,
            REACT_APP_PROJECT,
            component,
          )
        : component?.state?.journeys
      let selected = itineraries?.selected || 0

      if (journeys?.length > 0) {
        if (typeof selected === 'string') {
          if (journeys.find(j => j.id === selected)) {
            selected = journeys.findIndex(j => j.id === selected)
          } else {
            selected = 0
            message({
              warning: 'selected has been force to 0',
            })
          }
        }
        if (!Number.isInteger(selected) || !journeys[selected]) {
          selected = 0
          message({
            warning: 'selected has been force to 0',
          })
        }

        if (component && component.state) {
          if (Object.keys(journeys).length) {
            component.setState(
              {
                journeys: journeys,
                journey: journeys[selected],
              },
              () => {
                component.displayJourneys(journeys, map)
              },
            )
          } else {
            component.setState({ journeys: undefined, journey: null }, () => {
              component.displayJourneys(undefined, map)
            })
          }
        } else {
          message({
            error: "itineraries doesn't exist",
          })
        }
      }
    }

    if (event.data.line) {
      appStore.dispatch(actionSetCustomMarkers([]))

      let line

      if (typeof event.data.line === 'string') {
        line = appStore.getState().app.lines.find(l => l.id === event.data.line)
      } else if (typeof event.data.line === 'object') {
        line = new LineInterface(event.data.line, REACT_APP_PROJECT)
        const stops = line.stops

        delete line.stops

        if (appStore.getState().app.lines.find(l => l.id === line.id) === undefined) {
          appStore.dispatch(actionAddLine(line))
          appStore.dispatch(actionAddStops(stops))
        }
      }

      if (line) {
        if (event.data.route_id) {
          const route = line.routes.find(r => r.route_id === event.data.route_id)
          if (route) {
            line.direction_id = route.direction_id
          }
        }
        buildLinePath(line, appStore.getState().app.hash).then(polyline => {
          if (polyline && polyline.props.data.features) {
            appStore.dispatch(actionSetLineSelectedPath(polyline))

            if (event.data.stop_id === undefined) {
              fitBounds(appStore.getState().app.map, [polyline])
            }
          }
        })
      }
    }

    if (event.data.route_id) {
      appStore.dispatch(actionSetCustomMarkers([]))

      const allStops = appStore.getState().app.stops
      const map = appStore.getState().app.map
      let line = false

      if (event.data.line) {
        if (typeof event.data.line === 'string') {
          line = appStore.getState().app.lines.find(l => l.id === event.data.line)
        } else if (typeof event.data.line === 'object') {
          line = new LineInterface(event.data.line, REACT_APP_PROJECT)
        }
      } else {
        line = appStore.getState().app.lines.find(l => l.routes.find(r => r.route_id === event.data.route_id))
      }

      if (line) {
        let stops = []

        if (line.stops) {
          stops = line.stops
        } else {
          const route = line.routes.find(r => r.route_id === event.data.route_id)

          if (route) {
            if (!event.data.line) {
              buildLinePath({ ...line, direction_id: route.direction_id }, appStore.getState().app.hash).then(
                polyline => {
                  if (polyline && polyline.props.data.features) {
                    appStore.dispatch(actionSetLineSelectedPath(polyline))

                    if (event.data.stop_id === undefined) {
                      fitBounds(appStore.getState().app.map, [polyline])
                    }
                  }
                },
              )
            }

            const response = await axios.get(
              `/api/file?folder=stops&name=${encodeURIComponent(route.id)}~${appStore.getState().app.hash}`,
            )

            if (response && response.data) {
              stops = response.data
            } else {
              stops = allStops.filter(stop => stop.lines.map(l => l.route_id).includes(event.data.route_id))
            }
          }
        }

        const newMarkers = []

        for (let stop of stops) {
          if (
            (event.data.stop_id?.startsWith('stop_point') && stop.id === event.data.stop_id) ||
            (event.data.stop_id?.startsWith('stop_area') && stop.stop_area === event.data.stop_id)
          ) {
            const popupcontent = new CustomPopupInterface(
              { schedules: event.data.schedules, line: line },
              REACT_APP_PROJECT,
            )

            stop.content = popupcontent.content
          }

          newMarkers.push(
            appStore.dispatch(
              actionBuildCustomMarker(stop, {
                key: event.data.route_id + '_' + stop.index,
                postMessageEventName: 'stop',
                icon: new L.DivIcon({
                  className: `lc-circle-icon-marker`,
                  iconSize: [6, 6],
                  tooltipAnchor: new L.Point(5, 0),
                  html: `<span style="border: 2px solid #${line.color}" />`,
                }),
                zIndexOffset: 50,
              }),
            ),
          )
        }

        appStore.dispatch(actionAddCustomMarkers(newMarkers))

        for (let stop of stops) {
          if (
            (event.data.stop_id?.startsWith('stop_point') && stop.id === event.data.stop_id) ||
            (event.data.stop_id?.startsWith('stop_area') && stop.stop_area === event.data.stop_id)
          ) {
            // Test Safari
            setTimeout(() => {
              stop.ref.leafletElement.openPopup()
              updatePopupPosition(stop.ref.leafletElement)
            })

            if (map?.mapReference?.current?.leafletElement) {
              map.mapReference.current.leafletElement.setView(
                [+stop.coord.lat, +stop.coord.lon],
                map.mapReference.current.leafletElement.getZoom(),
              )
            }
          } else {
            if (stop.ref.leafletElement.isPopupOpen()) {
              stop.ref.leafletElement.closePopup()
            }
          }
        }

        if (event.data.stop_id === undefined) {
          fitBounds(
            appStore.getState().app.map,
            stops.map(s => [s.coord.lat, s.coord.lon]),
          )
        }

        // check if stop_id and schedules to add realtime bus icon
        if (event.data.stop_id !== undefined && event.data.schedules?.length > 0) {
          checkMoreDataReceived({ schedules: event.data.schedules, line: line }, REACT_APP_PROJECT)
        }

        // on applique le style sur les vignettes des terminus
        setTimeout(() => {
          // TODO find a better way :-)
          document.querySelectorAll('.lc-tooltip-leaflet-terminus').forEach(div => {
            div.style.borderColor = '#' + line.color

            div.style.backgroundColor = '#' + line.color
            div.style.color = luminance(line.color) > 0.5 ? '#333' : '#fff'
            div.style.padding = '2px 8px'
          })
        })
      } else {
        message({
          error: 'line_not_found_for_this_route',
        })
      }
    }

    if (event.data.hasAuthorizedCookies !== undefined) {
      appStore.dispatch(actionSetAppTracking(event.data.hasAuthorizedCookies === true))

      if (document.querySelector('.cookies-banner')) {
        document.querySelector('.cookies-banner').style.display = 'none'
      }
    }
  }
}

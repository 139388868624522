import { MapLayer, withLeaflet } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet-polylinedecorator'
import utils from 'leaflet-geometryutil'
import { luminance } from 'luminance-js'
import { assetsPath, getURLSearchParams } from '../../services/tools'
import history from '../../history'

const SECTION_MIN_TIME_FOR_DECORATOR = 500

// TODO Must be a better way to handle those fuckin plugins ...
class PolylineDecorator extends MapLayer {
  componentWillUnmount() {
    const { map } = this.props.leaflet

    map.removeLayer(this.leafletElement)
  }

  createLeafletElement({ path, section, lineStyle, modes, repeatPolylineDecorator = true, leaflet, offset }) {
    const code = section.display_informations ? section.display_informations.code : section.mode
    const length = repeatPolylineDecorator === true ? utils.length(path.map(p => new L.LatLng(p[0], p[1]))) : 0
    const params = getURLSearchParams(history.location)
    const displayBikeInsteedOfBssOnMap = this.props.displayBikeInsteedOfBssOnMap === true
    const displayCustomPolylineIcon = this.props.displayCustomPolylineIcon
    const forceLinesPictosOverMode = this.props.forceLinesPictosOverMode
    const walkingSpeed = this.props.walkingSpeed

    const bss =
      section.from &&
      modes.bss &&
      section.from.poi &&
      section.from.poi.poi_type.id.includes('bicycle_rental') &&
      section.to.poi &&
      section.to.poi.poi_type.id.includes('bicycle_rental') &&
      section.mode_display !== 'personal_bike' &&
      !displayBikeInsteedOfBssOnMap

    const mode = [section.display_informations?.physical_mode, section.display_informations?.commercial_mode].includes(
      'Train',
    )
      ? 'train'
      : section.display_informations?.commercial_mode === 'TER'
      ? 'ter'
      : [section.display_informations?.physical_mode, section.display_informations?.commercial_mode].includes(
          'Coach',
        ) && displayCustomPolylineIcon.coach !== undefined
      ? displayCustomPolylineIcon.coach
      : [section.display_informations?.physical_mode, section.display_informations?.commercial_mode].includes(
          'Autocar',
        ) && displayCustomPolylineIcon.autocar !== undefined
      ? displayCustomPolylineIcon.autocar
      : section.display_informations
      ? 'bus'
      : section.mode === 'car' && displayCustomPolylineIcon.car === undefined
      ? 'car'
      : section.mode === 'car' && displayCustomPolylineIcon.car !== undefined
      ? displayCustomPolylineIcon.car
      : section.mode === 'taxi'
      ? 'taxi'
      : section.mode === 'bike'
      ? bss
        ? 'bss'
        : 'bike'
      : modes.pmr
      ? 'pmr'
      : section.mode === 'ridesharing' || section.isRidesharing
      ? 'ridesharing'
      : walkingSpeed
      ? `walking-speed-${walkingSpeed}`
      : 'walk'

    const zoom = leaflet.map.getZoom()

    return L.polylineDecorator(path, {
      interactive: false,
      patterns: [
        {
          offset: offset !== undefined ? offset : '50%',
          repeat:
            repeatPolylineDecorator === true
              ? 100 - length / 400 + '%'
              : typeof repeatPolylineDecorator === 'string'
              ? repeatPolylineDecorator
              : false,
          symbol: L.Symbol.marker({
            rotate: false,
            markerOptions: {
              interactive: false,
              icon:
                lineStyle === 'color' && section.display_informations
                  ? L.divIcon({
                      className: 'lc-polyline-decorator-color',
                      html:
                        '<div class="lc-decorator" style="background: #' +
                        section.display_informations.color +
                        '; color: #' +
                        (luminance(section.display_informations.color) > 0.5 ? '333' : 'fff') +
                        '">' +
                        code +
                        '</div>',
                    })
                  : L.icon({
                      className: `lc-polyline-decorator ${
                        mode === 'ter'
                          ? 'lc-decorator-ter'
                          : mode === 'bus'
                          ? 'lc-decorator-bus'
                          : section.mode === 'bike' || mode === 'car'
                          ? 'lc-decorator-bike-or-car'
                          : section.mode === 'ridesharing'
                          ? 'lc-decorator-ridesharing'
                          : 'lc-decorator-other ' + mode
                      } ${
                        mode === 'walk' &&
                        section.duration <= SECTION_MIN_TIME_FOR_DECORATOR &&
                        (params.journey || (!params.journey && zoom < 16)) &&
                        'lc-hide-walk'
                      } ${
                        mode === 'walk' &&
                        section.duration <= SECTION_MIN_TIME_FOR_DECORATOR &&
                        (params.journey || (!params.journey && zoom > 15)) &&
                        'lc-show-walk'
                      }`,
                      iconUrl:
                        mode === 'bus' || mode === 'ter' || forceLinesPictosOverMode[mode]
                          ? assetsPath(`/assets/images/lines/${code}.svg`)
                          : assetsPath(`/assets/images/modes/${mode}.svg`),
                    }),
            },
          }),
        },
      ],
    })
  }

  updateLeafletElement(fromProps, { path }) {
    this.leafletElement.setPaths(path)
  }
}

export default withLeaflet(PolylineDecorator)

import { useSelector } from 'react-redux'
import styled from 'styled-components'
import PrintItineraryRoadmap from '../print/PrintItineraryRoadmap'
import PrintItineraryMap from '../print/PrintItineraryMap'
import PrintItineraryMapWalk from '../print/PrintItineraryMapWalk'
import PrintTimetable from '../print/PrintTimetable'
import history from '../history'
import { getURLSearchParams } from '../services/tools'

const Document = styled.div`
  /* padding: 30px; */
`

const Print = () => {
  const configApp = useSelector(state => state.app.configApp)
  const printState = useSelector(state => state.app.print)
  const domElement = useSelector(state => state.app.domElement)
  const { datas, pages } = printState
  const params = getURLSearchParams(history.location)

  if (document.querySelector(`${domElement} header`)) {
    document.querySelector(`${domElement} header`).remove()
  }

  return (
    <Document>
      {pages.map((page, index) => {
        switch (page.type) {
          case 'itinerary-roadmap':
            return <PrintItineraryRoadmap key={`print-page-${index}`} page={page} datas={datas} modes={params?.modes} />
          case 'itinerary-map':
            return <PrintItineraryMap key={`print-page-${index}`} page={page} datas={datas} configApp={configApp} />
          case 'itinerary-map-walk':
            return <PrintItineraryMapWalk key={`print-page-${index}`} page={page} datas={datas} configApp={configApp} />
          case 'timetable':
            return <PrintTimetable key={`print-page-${index}`} page={page} datas={datas} data={params.date} />
          default:
            return <></>
        }
      })}
    </Document>
  )
}

export default Print

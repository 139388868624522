export default class BikeInterface {
  constructor(bike, project) {
    switch (project) {
      case 'arras-artis':
        this.id = bike.name
        this.cat_id = 'poi_type:amenity:bicycle_rental'
        this.name = bike.name
        this.address = bike.streetAddress + '\n' + bike.postalCode + ' ' + bike.addressLocality
        this.capacity = bike.capacity
        this.availablePlaces = bike.availablePlaces
        this.coord = {
          lat: bike.latitude,
          lon: bike.longitude,
        }
        break
      case 'annecy-mobilites':
      case 'annecy-mobilites-2024': {
        this.id = bike.id
        this.cat_id = 'poi_type:amenity:bicycle_rental'
        this.code = 'amenity_bicycle_rental'
        this.name = bike.name
        this.is_available = bike.is_available
        this.availableBikes = bike.nb_bike_available
        this.available_bikes = bike.nb_bike_available
        this.coord = {
          lat: bike.latitude,
          lon: bike.longitude,
        }
        this.type = bike.type
        this.radius = [2, 4].includes(bike.type) ? bike.radius : null
        this.caution = bike.caution / 100
        this.hide = bike.state === 1

        let business_hours = ''

        const week = {
          1: 'Mo',
          2: 'Tu',
          3: 'We',
          4: 'Th',
          5: 'Fr',
          6: 'Sa',
          7: 'Su',
        }

        for (const [index, day] of bike.business_hours.entries()) {
          const dayWeek = day.day

          const hours = day.is_open
            ? day.is_continuous_day
              ? `${day.morning_start}-${day.morning_end}`
              : `${day.morning_start}-${day.morning_end}/${day.afternoon_start}-${day.afternoon_end}`
            : 'closed'

          if (index !== 0) {
            const days = business_hours.split(';').filter(e => String(e).trim())
            const last_day = days[days.length - 1].split(' ')

            if (last_day[1] === hours && last_day[0].includes('-')) {
              business_hours = business_hours.replace(`${last_day[0].split('-')[1]}`, week[dayWeek])
            } else if (last_day[1] === hours && !last_day[0].includes('-')) {
              business_hours = business_hours.replace(`${last_day[0]}`, `${last_day[0]}-${week[dayWeek]}`)
            } else {
              business_hours += `${week[dayWeek]} ${hours};`
            }
          } else {
            business_hours += `${week[dayWeek]} ${hours};`
          }
        }

        // this.opening_hours = business_hours.slice(0, -1);

        // let prices = {};

        // for (const [index, p] of bike.slots.entries()) {
        //   let time = p.time;

        //   if (index !== 0) {
        //     const last = Object.keys(prices)[Object.keys(prices).length - 1];

        //     time = parseInt(time) + parseInt(last);
        //     prices[time] = p.price / 100 + parseFloat(prices[last]);
        //   } else {
        //     prices[time] = p.price / 100;
        //   }
        // }

        // this.prices = prices;

        this.website = 'http://www.velonecy.com/fr'

        // this.price_url = "http://www.velonecy.com/fr/velonecy-60-minutes-le-service-de-location-de-velo-courte-duree";

        break
      }
      case 'toulouse-tisseo': {
        this.id = `poi:amenity:bicycle_rental:${bike.veloStation}`
        this.cat_id = 'poi_type:amenity:bicycle_rental'
        this.name = bike.label
        this.address = bike.address + ' ' + bike.cityName
        const availableBikes = bike.available_bikes ? parseInt(bike.available_bikes) : 0
        const availablePlaces = bike.available_bike_stands ? parseInt(bike.available_bike_stands) : 0

        this.capacity = availableBikes + availablePlaces
        this.availableBikes = availableBikes
        this.availablePlaces = availablePlaces

        const ratio = Math.ceil((availableBikes / this.capacity) * 100)

        if (ratio >= 0 && ratio <= 10) {
          this.code = 'velo_toulouse_0'
        } else if (ratio > 10 && ratio <= 20) {
          this.code = 'velo_toulouse_10'
        } else if (ratio > 20 && ratio <= 30) {
          this.code = 'velo_toulouse_20'
        } else if (ratio > 30 && ratio <= 40) {
          this.code = 'velo_toulouse_30'
        } else if (ratio > 40 && ratio <= 50) {
          this.code = 'velo_toulouse_40'
        } else if (ratio > 50 && ratio <= 60) {
          this.code = 'velo_toulouse_50'
        } else if (ratio > 60 && ratio <= 70) {
          this.code = 'velo_toulouse_60'
        } else if (ratio > 70 && ratio <= 80) {
          this.code = 'velo_toulouse_70'
        } else if (ratio > 80 && ratio <= 90) {
          this.code = 'velo_toulouse_80'
        } else if (ratio > 90 && ratio <= 100) {
          this.code = 'velo_toulouse_90'
        } else {
          this.code = 'velo_toulouse_100'
        }

        // this.stand = { available_bikes: availableBikes, available_places: availablePlaces };
        this.coord = {
          lat: bike.y,
          lon: bike.x,
        }
        break
      }
      case 'montelimar-montelibus': {
        this.id = `poi:amenity:bicycle_rental:${bike.station_id}`
        this.cat_id = 'poi_type:amenity:bicycle_rental'
        this.name = bike.name
        // this.address = bike.address + ' ' + bike.cityName
        this.code = 'amenity_bicycle_rental'

        const availableBikes = bike.available_bikes ? parseInt(bike.available_bikes) : 0
        const availablePlaces = bike.available_bike_stands ? parseInt(bike.available_bike_stands) : 0

        this.capacity = availableBikes + availablePlaces

        this.availableBikes = availableBikes
        this.availablePlaces = availablePlaces
        this.is_available = true
        this.coord = {
          lat: bike.lat,
          lon: bike.lon,
        }
        break
      }
      default:
        break
    }
  }
}

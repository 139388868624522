import { useRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import history from '../history'
import { assetsPath, translate, getURLSearchParams } from '../services/tools'
import { navitiaDateToDate, dateToLibelle } from '../utils/tools'
import { Map, TileLayer, Pane } from 'react-leaflet'
import L from 'leaflet'
import SectionIcon from '../components/SectionIcon'
import exportedSCSS from '../scss/app.scss'

const {
  REACT_APP_START_POINT,
  REACT_APP_PROJECT,
  REACT_APP_ZOOM,
  REACT_APP_MBTILES,
  REACT_APP_URL_TILES,
  REACT_APP_ABOVE_TILES_URL,
} = process.env
const { primarycolor, errorcolor, printBackgroundColor } = exportedSCSS

const BasePage = styled.div`
  page-break-after: always;
  break-after: always;

  .lc-elevation {
    margin: 0 !important;

    .lc-line-header {
      padding: 0 0 10px !important;
      margin: 0 10px;

      .lc-line {
        padding: 10px 10px 10px 0 !important;
      }

      .lc-direction {
        margin-left: 0 !important;
      }
    }
    .lc-timetable-stop {
      padding: 15px 0 !important;
      margin: 0 10px;
    }
    .lc-timetable {
      margin-top: 0 !important;
      padding: 0 !important;
    }
    .lc-otherDirections {
      margin-left: 10px !important;
    }
  }
`

const OldPage = styled(BasePage)`
  padding-top: 30px;
  padding-bottom: 30px;
`

const NewPage = styled(BasePage)`
  .journey-date {
    font-size: 48px;
  }
  .lc-cat-picto {
    width: 80px;
    margin-left: 40px;
  }
`

export const Page = props => {
  const { configApp } = useSelector(state => state.app)

  if (configApp.new_print_design) {
    return <NewPage {...props}>{props.children}</NewPage>
  } else {
    return <OldPage {...props}>{props.children}</OldPage>
  }
}

const OldHeader = styled.div`
  display: flex;
  padding-left: 10px;
  margin-bottom: 15px;
`

const NewHeader = styled.div`
  background-color: ${printBackgroundColor};
  min-height: 190px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 8px;
`

export const Header = props => {
  const { configApp } = useSelector(state => state.app)

  if (configApp.new_print_design) {
    return <NewHeader>{props.children}</NewHeader>
  } else {
    return <OldHeader>{props.children}</OldHeader>
  }
}

export const LeftSideContainer = styled.div`
  display: flex;
  padding-left: 10px;
  margin-bottom: 15px;
`

const OldTitle = styled.div`
  background-color: ${primarycolor};
  border: solid black 1px;
  border-radius: 5px;
  border-left: none;
  height: 50px;
  align-items: center;
  display: flex;
  margin-left: 10px;
`

const NewTitle = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 28px;
`

export const Title = props => {
  const { configApp } = useSelector(state => state.app)

  if (configApp.new_print_design) {
    return <NewTitle>{props.children}</NewTitle>
  } else {
    return <OldTitle>{props.children}</OldTitle>
  }
}

export const HeaderPictosContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 250px;
  gap: 20px;
`

export const TitleTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 32px;
  h1,
  p {
    margin: 0;
  }
`

const OldTitleText = styled.h1`
  color: #ffffff;
  padding: 0 10px 0 15px;
`

const NewTitleText = styled.h1`
  color: ${primarycolor};
`

export const TitleText = props => {
  const { configApp } = useSelector(state => state.app)

  if (configApp.new_print_design) {
    return <NewTitleText>{props.children}</NewTitleText>
  } else {
    return <OldTitleText>{props.children}</OldTitleText>
  }
}

const OldTitleImg = styled.div`
  width: 50px;
  height: 50px;
  border: solid white 1px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
  margin-left: -10px;
  background: url(${props => assetsPath(`/assets/images/menu/${props.image ? props.image : 'lines'}.svg`)}) #fff
    no-repeat center;
`

const NewTitleImg = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 5px;
  background: url(${props => assetsPath(`/assets/images/menu/${props.image ? props.image : 'lines'}.svg`)}) #fff
    no-repeat center;
  margin-right: 32px;
  background-color: transparent;

  img {
    width: 100%;
    height: auto;
  }
`

export const TitleImg = props => {
  const { configApp } = useSelector(state => state.app)

  if (configApp.new_print_design) {
    return <NewTitleImg image={props.image}>{props.children}</NewTitleImg>
  } else {
    return <OldTitleImg>{props.children}</OldTitleImg>
  }
}

export const MainContainer = styled.div`
  margin: 0 20px;
`

export const BoldTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const ContentImg = styled.div`
  height: 35px;
  width: 35px;
  background-color: ${primarycolor};
  border-radius: 2px;
  background: url(${props => assetsPath(`/assets/images/menu/${props.image ? props.image : 'lines'}.svg`)}) #fff
    no-repeat center;
`

export const Footer = styled.footer`
  color: ${() => errorcolor};
`

export const IconWarning = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 5px;
`

export const Text = styled.p`
  color: ${() => primarycolor};
`

export const Texts = styled.div`
  padding-left: 10px;
`

const OldTextInBold = styled.p`
  color: ${() => primarycolor};
  font-weight: bold;
  font-size: 18px;
`

const NewTextInBold = styled(OldTextInBold)`
  background-color: ${printBackgroundColor};
  border-radius: 8px;
  padding: 8px 16px;
`

export const TextInBold = props => {
  const { configApp } = useSelector(state => state.app)

  if (configApp.new_print_design) {
    return <NewTextInBold>{props.children}</NewTextInBold>
  } else {
    return <OldTextInBold>{props.children}</OldTextInBold>
  }
}

export const ItalicText = styled.p`
  font-style: italic;
`

export const HeaderJourney = props => {
  const { component, journey, modes, walkingSpeeds } = props

  return (
    <ul className="lc-roadmap-summary">
      <li className="lc-duration">
        <div role="heading" aria-level="3">
          {translate('route-calculation-journey-summary-duration')}{' '}
          <span>
            {component.duration(journey.duration).hours > 0
              ? component.duration(journey.duration).hours +
                'h' +
                (component.duration(journey.duration).minutes > 9
                  ? component.duration(journey.duration).minutes
                  : '0' + component.duration(journey.duration).minutes)
              : component.duration(journey.duration).minutes}{' '}
            {component.duration(journey.duration).hours === 0 && translate('route-calculation-duration-minutes')}
          </span>
        </div>
      </li>
      {(!journey.geovelo || (journey.geovelo && journey.pedestrian)) && (
        <li className="lc-walk">
          <div>
            <SectionIcon
              image={
                modes.pmr
                  ? 'modes/pmr'
                  : walkingSpeeds.length > 0
                  ? `modes/${walkingSpeeds.find(ws => ws.value).type}`
                  : 'modes/walk'
              }
              className="lc-walk"
              type="walk"
              options={{ height: 12, padding: '0 5px 0 0' }}
            />
            {Math.floor(
              journey.sections.reduce((acc, section) => {
                if (
                  section.mode === 'walking' ||
                  section.type === 'transfer' ||
                  section.type === 'park' ||
                  section.type === 'bss_rent' ||
                  section.type === 'bss_put_back'
                ) {
                  return acc + Math.floor(section.duration / 60) * 60 // passage en minutes arrondit à l'inférieur avant de repasser en secondes...
                } else {
                  return acc
                }
              }, 0) / 60,
            )}{' '}
            {translate('minute-split')}
          </div>
        </li>
      )}
      {journey.calories && (
        <li className="lc-calories">
          <div>
            <SectionIcon
              image="calories"
              className="lc-calories"
              type="calories"
              options={{ height: 12, padding: '0 5px 0 0' }}
            />
            {Math.floor(journey.calories)} kcal
          </div>
        </li>
      )}
      {journey.co2_not_emitted ? (
        <div>
          <SectionIcon
            image="leaf-not"
            className="lc-leaf-not"
            type="leaf-not"
            options={{ height: 12, padding: '0 5px 0 0' }}
          />
          {`${+parseFloat(journey.co2_not_emitted.value).toFixed(2)} ${journey.co2_not_emitted.unit}`}
        </div>
      ) : (
        <div>
          <SectionIcon image="leaf" className="lc-leaf" type="leaf" options={{ height: 12, padding: '0 5px 0 0' }} />
          {`${Math.floor(journey.co2_emission.value)} g`}
        </div>
      )}
    </ul>
  )
}

export const Summary = props => {
  const { language, configApp } = useSelector(state => state.app)
  const { component } = props
  const { extendArea, extendAreaValue, journey } = component.state
  const { walkingSpeeds, inputStartValue, inputEndValue, modes } = component.props
  const params = getURLSearchParams(history.location)
  const dateIti = navitiaDateToDate(journey.departure_date_time)
  let modesIti = []

  if (params.modes) {
    for (const m of Object.keys(modes)) {
      if (m !== 'avoid' && m !== 'pmr') {
        if (params.modes.includes(',' + m)) {
          modesIti.push(translate('modes-' + m, false))
        }
      }
    }
  }

  const showTravelerProfil = walkingSpeeds.length > 0 || params.modes?.includes('pmr')
  let walkingIti = []

  if (params.modes && params.modes.includes('pmr')) {
    walkingIti.push(translate('modes-pmr'))
  }

  if (params.walking) {
    const findWalkingSpeed = walkingSpeeds.find(ws => parseFloat(ws.speed) === parseFloat(params.walking))

    walkingIti.push(
      translate(
        'route-calculation-walking-speed-' + (findWalkingSpeed !== undefined ? findWalkingSpeed.type : 'normal'),
      ),
    )
  }

  return (
    <div className="lc-journeys-search-summary lc-elevation">
      <div className="lc-journeys-search-summary-text">
        {configApp.new_print_design ? (
          <BoldTextContainer>
            <ContentImg className="lc-print-title-image" image="picto-itinerary" />
            <TextInBold>{translate('print-page-your-itinerary')}</TextInBold>
          </BoldTextContainer>
        ) : (
          <TextInBold>{translate('print-page-your-itinerary')}</TextInBold>
        )}
        <div className="lc-journeys-search-summary-from">
          {translate('label-inputStart')} {inputStartValue}
        </div>
        <div className="lc-journeys-search-summary-end">
          {translate('label-inputEnd')} {inputEndValue}
        </div>
        <div className="lc-journeys-search-summary-date">
          <span>{params.modes.includes('departure') ? translate('departure') : translate('arrival')}</span>
          {' ' +
            dateToLibelle(dateIti, language, 'full') +
            ', ' +
            dateIti.toLocaleTimeString(language, { hour: '2-digit', minute: '2-digit' })}
        </div>
        <div className="lc-journeys-search-summary-modes">
          <span>{translate('route-calculation-pref')}</span> {modesIti.join(', ')}
        </div>
        {showTravelerProfil && (
          <div className="lc-journeys-search-summary-traveler-profil">
            <span>{translate('route-calculation-walking-speed')}</span> {walkingIti.join(', ')}
          </div>
        )}
        {extendArea && extendAreaValue && (
          <div className="lc-journeys-search-summary-extend-area">
            <span>{translate('route-calculation-expand-area-value')}</span> {extendAreaValue}m
          </div>
        )}
      </div>
    </div>
  )
}

export const ItineraryMap = props => {
  const mapRef = useRef()
  const center = JSON.parse(REACT_APP_START_POINT).desktop
  const zoom = JSON.parse(REACT_APP_ZOOM)
  const { component, journey, section, size, configApp } = props
  const [bounds, setBounds] = useState(L.latLngBounds())
  const [polylines, setPolylines] = useState([])
  const [markers, setMarkers] = useState([])
  const [decorators, setDecorators] = useState([])

  useEffect(() => {
    const displayJourney = async () => {
      let polylines = []
      let markers = []
      let decorators = []
      let bounds = L.latLngBounds()

      markers.push(...component.props.map.state.markers.filter(m => ['inputStart-pin', 'inputEnd-pin'].includes(m.key)))

      await component.displayJourney(journey, 0, 0, polylines, markers, decorators)

      if (section?.geojson) {
        let coords = section.geojson.coordinates

        coords = coords.map(coord => [coord[1], coord[0]])

        bounds.extend(coords)
      } else {
        polylines.forEach(p => {
          bounds.extend(p?.props?.positions)
        })
        markers.forEach(m => {
          bounds.extend(m?.props?.position)
        })
      }

      if (bounds.isValid()) {
        setBounds(bounds)
      }

      setPolylines(polylines)
      setMarkers(markers)
      setDecorators(decorators)
    }

    displayJourney()

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (mapRef?.current?.leafletElement && bounds.isValid()) {
      mapRef.current.leafletElement.fitBounds(bounds, {
        paddingTopLeft: size === 'small' ? L.point(10, 10) : L.point(50, 50),
        paddingBottomRight: size === 'small' ? L.point(10, 10) : L.point(50, 50),
        animate: false,
      })
    }

    // eslint-disable-next-line
  }, [mapRef, bounds])

  return (
    <Map
      ref={mapRef}
      className={`lc-print-itinerary-map ${size ? size : ''}`}
      center={center}
      zoom={14}
      maxZoom={zoom.max}
      minZoom={zoom.min}
      dragging={false}
      doubleClickZoom={false}
      keyboard={false}
      tap={false}
      touchZoom={false}
      zoomControl={false}
      attributionControl={false}
      style={configApp.new_print_design ? { height: '800px' } : {}}>
      <TileLayer
        errorTileUrl={assetsPath('/assets/images/blank.png')} // Grey tile if 404
        url={
          configApp?.print_tiles_url
            ? configApp.print_tiles_url
            : configApp?.tiles
            ? configApp.tiles
            : `https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png`
        }
      />
      {REACT_APP_ABOVE_TILES_URL && (
        <Pane name="tilelayer-above">
          <TileLayer
            errorTileUrl={assetsPath('/assets/images/blank.png')} // Grey tile if 404
            url={
              REACT_APP_ABOVE_TILES_URL === 'mbtiles'
                ? `https://${REACT_APP_URL_TILES !== undefined ? REACT_APP_URL_TILES : 'tiles.lc.tools'}/services/${
                    REACT_APP_MBTILES !== undefined ? REACT_APP_MBTILES : REACT_APP_PROJECT
                  }-above/tiles/{z}/{x}/{y}.png`
                : REACT_APP_ABOVE_TILES_URL === 'mbtiles-rendering-test'
                ? `https://${REACT_APP_URL_TILES !== undefined ? REACT_APP_URL_TILES : 'tiles.lc.tools'}/services/${
                    REACT_APP_MBTILES !== undefined ? REACT_APP_MBTILES : REACT_APP_PROJECT
                  }-above-rendering-test/tiles/{z}/{x}/{y}.png`
                : `${REACT_APP_ABOVE_TILES_URL}/png/{z}/{x}/{y}.png`
            }
          />
        </Pane>
      )}
      {polylines} {markers} {decorators}
    </Map>
  )
}

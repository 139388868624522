import { useSelector } from 'react-redux'
import { assetsPath, translate } from '../services/tools'
import { formatDate, navitiaDateToDate } from '../utils/tools'
import {
  Page,
  Header,
  Title,
  TitleText,
  TitleImg,
  Text,
  TitleTextContainer,
  BoldTextContainer,
  ContentImg,
  MainContainer,
} from './commun'
import { Summary, ItineraryMap, TextInBold } from './commun'
import styled from 'styled-components'

const MapContent = styled.div`
  padding-left: 10px;
`

const PrintItineraryMap = props => {
  const { datas, page, configApp } = props
  const { component, journey } = datas
  const { language } = useSelector(state => state.app)
  const journeyDate = formatDate(navitiaDateToDate(journey.departure_date_time), 'full-with-year', language)

  return (
    <Page>
      <Header>
        <Title>
          {configApp.new_print_design ? (
            <>
              <TitleTextContainer>
                <TitleText className="lc-print-title-text">{translate('print-page-title')}</TitleText>
                <Text className="journey-date">
                  {translate('journey-date', false, { key: 'date', value: journeyDate })}
                </Text>
              </TitleTextContainer>
              <TitleImg className="lc-print-title-image" image="block-logo" />
            </>
          ) : (
            <>
              <TitleImg src={assetsPath('/assets/images/menu/route-calculation.svg')} />
              <TitleText>{translate('print-page-title')}</TitleText>
            </>
          )}
        </Title>
      </Header>
      {configApp.new_print_design ? (
        <MainContainer>
          {page.summary && <Summary component={component} />}
          <MapContent>
            <BoldTextContainer>
              <ContentImg className="lc-print-title-image" image="picto-journey" />
              <TextInBold>{translate('print-page-my-trip')}</TextInBold>
            </BoldTextContainer>
            <ItineraryMap component={component} journey={journey} configApp={configApp} />
          </MapContent>
        </MainContainer>
      ) : (
        <>
          {page.summary && <Summary component={component} />}
          <MapContent>
            <TextInBold>{translate('print-page-my-trip', false)}</TextInBold>
            <ItineraryMap component={component} journey={journey} configApp={configApp} />
          </MapContent>
        </>
      )}
    </Page>
  )
}

export default PrintItineraryMap

import Tippy from '@tippy.js/react'
import { assetsPath, envVarToBool, navitiaDateToHoursMin, translate } from '../../services/tools'
import { dateFromNow, displayDateFromNow, navitiaDateToDate } from '../../utils/tools'

const { REACT_APP_NEXT_SCHEDULE_CLASSIC_FORMAT } = process.env

const UIStopScheduleTime = props => {
  const { configApp, schedule, language } = props

  return (
    <div className={`lc-schedule${schedule.realtime ? ' lc-schedule-realtime' : ''}`}>
      {dateFromNow(new Date(navitiaDateToDate(schedule.time))).days < 1 &&
      dateFromNow(new Date(navitiaDateToDate(schedule.time))).hours < 1 &&
      dateFromNow(new Date(navitiaDateToDate(schedule.time))).seconds > 0 &&
      (envVarToBool(REACT_APP_NEXT_SCHEDULE_CLASSIC_FORMAT) === false ||
        (envVarToBool(REACT_APP_NEXT_SCHEDULE_CLASSIC_FORMAT) === true && schedule.realtime)) &&
      (!configApp?.theorical_time_classic_format || (configApp?.theorical_time_classic_format && schedule.realtime)) ? (
        <time style={{ whiteSpace: 'nowrap' }}>
          {displayDateFromNow(new Date(navitiaDateToDate(schedule.time)), true)}
        </time>
      ) : (
        navitiaDateToHoursMin(schedule.time, language, schedule.extend)
      )}
      {schedule.realtime && (
        <Tippy
          theme={'latitude'}
          touch={['hold', 500]}
          placement={'right'}
          boundary="window"
          content={translate('realtime-gif-title')}>
          <img src={assetsPath('/assets/images/realtime.gif')} alt={translate('realtime-gif-alt')} />
        </Tippy>
      )}
      {schedule.equipments.includes('has_wheelchair_accessibility') && (
        <Tippy
          theme={'latitude'}
          touch={['hold', 500]}
          placement={'right'}
          boundary="window"
          content={translate('aria-lines-stop-pmr')}>
          <img src={assetsPath('/assets/images/pmr.svg')} alt={translate('aria-lines-stop-pmr')} />
        </Tippy>
      )}
    </div>
  )
}

export default UIStopScheduleTime

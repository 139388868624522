import { useRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { luminance } from 'luminance-js'
import exportedSCSS from '../../scss/app.scss'

const { REACT_APP_DISRUPTION } = process.env
const { lineImageHeight, errorcolor, warningcolor } = exportedSCSS

const ContentContainer = styled.div`
  position: relative;
  ${props => (props.inpopup ? `display:flex;` : 'margin-bottom:-5px;')}
  &.active #lc-line-overlay-content {
    opacity: 1;
  }

  .lc-blocking-icon,
  .lc-delays-icon {
    position: absolute;
    min-width: 16px !important;
    height: 16px !important;
    margin-top: -8px !important;
    margin-left: ${props => (props.inpopup ? '20px' : '26px')} !important;
    z-index: 998;
  }

  .lc-blocking-point,
  .lc-delays-point {
    position: absolute;
    margin-top: 0px !important;
    margin-left: ${props => (props.inpopup ? '16px' : '21px')} !important;
    &.lc-blocking-point {
      background: ${() => errorcolor};
    }
    &.lc-delays-point {
      background: ${() => warningcolor};
    }
    border: solid white 1px;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    z-index: 998;
  }
`

const OverlayContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #${props => props.color};
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  height: ${props => (props.styleLine.includes('image') ? lineImageHeight : '100%')};
  width: 100%;
  opacity: 0;
  transition: ${props => `all ${props.transitionAnimTime}ms ease-in-out 0s`};
  color: ${props => (luminance(props.color) > 0.5 ? '#333' : '#fff')};
  font-size: 1.2em;
  font-weight: bold;
  border-radius: 2px;
`

const UIDisruptedLineOverlay = props => {
  const {
    line,
    styleLine,
    startAnimFadeAt = 3000,
    transitionAnimTime = 1500,
    intervalAnim = 5500,
    inpopup = false,
  } = props

  const description = '!'
  const overlay = useRef()
  const { disruptions, disruptionsInLine } = useSelector(state => state.board)
  const { stops, areas } = useSelector(state => state.app)
  const [isDisruptedLine, setIsDisruptedLine] = useState(false)
  const hasOverlay = REACT_APP_DISRUPTION ? JSON.parse(REACT_APP_DISRUPTION).overlay : null
  const overlaySeverity = REACT_APP_DISRUPTION
    ? JSON.parse(REACT_APP_DISRUPTION).overlaySeverity || ['blocking']
    : ['blocking']

  useEffect(() => {
    let interval = undefined

    const animation = () => {
      setTimeout(() => {
        overlay.current && overlay.current.classList.add('active')
        setTimeout(() => {
          overlay.current && overlay.current.classList.remove('active')
        }, transitionAnimTime)
      }, startAnimFadeAt)
    }

    if (isDisruptedLine && !interval) {
      animation()

      interval = setInterval(() => {
        animation()
      }, intervalAnim)
    }

    return () => clearInterval(interval)
    // eslint-disable-next-line
  }, [isDisruptedLine])

  useEffect(() => {
    if ((disruptions && disruptions.length > 0) || (disruptionsInLine && disruptionsInLine.length > 0)) {
      const disruptionsToUse = disruptions && disruptions.length > 0 ? disruptions : disruptionsInLine
      const filteredDisruptions = []

      for (let i = 0; i < disruptionsToUse.length; i++) {
        const disruption = disruptionsToUse[i]

        if (overlaySeverity.includes(disruption.severity)) {
          for (let j = 0; j < disruption.impacted_objects.length; j++) {
            const impacted = disruption.impacted_objects[j]

            switch (impacted.type) {
              case 'line':
                if (impacted.line === line.id) {
                  filteredDisruptions.push(disruption)
                  continue
                }

                break
              case 'stop_point': {
                const stop = stops.find(s => s.id === impacted.id)

                if (stop && stop.lines.map(l => l.id).includes(line.id)) {
                  filteredDisruptions.push(disruption)
                  continue
                }

                break
              }
              case 'stop_area': {
                const area = areas.find(a => a.id === impacted.id)

                if (area && area.lines.map(l => l.id).includes(line.id)) {
                  filteredDisruptions.push(disruption)
                  continue
                }

                break
              }
              case 'section': {
                const from = impacted.from
                const to = impacted.to

                if (impacted.line === line.id) {
                  filteredDisruptions.push(disruption)
                  continue
                } else if (!impacted.line) {
                  if (from.includes('stop_point')) {
                    const stop = stops.find(s => s.id === from)

                    if (stop && stop.lines.map(l => l.id).includes(line.id)) {
                      filteredDisruptions.push(disruption)
                      continue
                    }
                  } else if (from.includes('stop_area')) {
                    const area = areas.find(a => a.id === from)

                    if (area && area.lines.map(l => l.id).includes(line.id)) {
                      filteredDisruptions.push(disruption)
                      continue
                    }
                  }

                  if (to.includes('stop_point')) {
                    const stop = stops.find(s => s.id === to)

                    if (stop && stop.lines.map(l => l.id).includes(line.id)) {
                      filteredDisruptions.push(disruption)
                      continue
                    }
                  } else if (to.includes('stop_area')) {
                    const area = areas.find(a => a.id === to)

                    if (area && area.lines.map(l => l.id).includes(line.id)) {
                      filteredDisruptions.push(disruption)
                      continue
                    }
                  }
                }

                break
              }
              default:
                break
            }
          }
        }
      }

      if (filteredDisruptions.find(d => d.severity === 'blocking') && overlaySeverity.includes('blocking')) {
        setIsDisruptedLine('blocking')
      } else if (filteredDisruptions.find(d => d.severity === 'delays') && overlaySeverity.includes('delays')) {
        setIsDisruptedLine('delays')
      }
    }
    // eslint-disable-next-line
  }, [disruptions, disruptionsInLine])

  return isDisruptedLine ? (
    <ContentContainer ref={overlay} inpopup={inpopup}>
      {hasOverlay && hasOverlay === 'fade' && isDisruptedLine && (
        <OverlayContainer
          color={line.color}
          styleLine={styleLine}
          transitionAnimTime={transitionAnimTime}
          id="lc-line-overlay-content">
          {description}
        </OverlayContainer>
      )}
      {hasOverlay && hasOverlay === 'picto' && isDisruptedLine && <div className={`lc-${isDisruptedLine}-icon`}></div>}
      {hasOverlay && hasOverlay === 'point' && isDisruptedLine && <div className={`lc-${isDisruptedLine}-point`}></div>}
      {props.children}
    </ContentContainer>
  ) : (
    props.children
  )
}

export default UIDisruptedLineOverlay

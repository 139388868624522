import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actionLoginSetToken } from '../actions/login'

const loginConfig = process.env.REACT_APP_LOGIN ? JSON.parse(process.env.REACT_APP_LOGIN) : null

const LogInValkyrie1 = ({ url, app, perms, origin }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    const handler = e => {
      if (e.origin === origin && e.data.source === 'valkyrie' && e.data.action === 'authComplete') {
        dispatch(actionLoginSetToken(e.data.token))
      }
    }

    window.addEventListener('message', handler)
    return () => window.removeEventListener('message', handler)
  }, [dispatch, origin])

  return (
    <div className="lc-login-valkyrie1">
      <iframe
        title="valkyrie1 login"
        src={`${url}?app=${encodeURIComponent(app)}&perms=${encodeURIComponent(perms.join(','))}`}
      />
    </div>
  )
}

const LogIn = () => {
  switch (loginConfig.mode) {
    case 'valkyrie1':
      return (
        <LogInValkyrie1
          url={loginConfig.valkyrie1.url}
          app={loginConfig.valkyrie1.app}
          perms={loginConfig.valkyrie1.perms}
          origin={loginConfig.valkyrie1.origin}
        />
      )
    default:
      return null
  }
}

export default LogIn

const { REACT_APP_PROJECT } = process.env

const UITerminusShape = ({ line }) => {
  switch (REACT_APP_PROJECT) {
    case 'annecy-mobilites':
      return (
        <svg
          className="lc-annecy-terminus-shape"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 22.68 22.68"
          width="20px"
          height="20px">
          <path
            fill={'#' + line.color}
            d="m12.34,0h-2c0,4.98-5.36,10.55-10.34,10.55v1.58c4.98,0,10.34,5.57,10.34,10.55h2c0-4.98,5.36-10.55,10.34-10.55v-1.58C17.7,10.55,12.34,4.98,12.34,0"
          />
        </svg>
      )
    default:
      return <></>
  }
}

export default UITerminusShape

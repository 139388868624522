import styled from 'styled-components'
import { assetsPath } from '../../services/tools'

const Arrow = styled.div`
  width: 20px;
  height: 20px;
  background: url(${() => assetsPath('/assets/images/menu-item-arrow.svg')}) no-repeat center center;

  transition: all 0.15s ease-in;

  transform: ${props => props.isOpen && 'rotate(90deg)'};
`
const ArrowTopBottom = styled.div`
  width: 20px;
  height: 20px;
  background: url(${() => assetsPath('/assets/images/menu-item-arrow.svg')}) no-repeat center center;

  transition: all 0.15s ease-in;

  transform: rotate(90deg);

  &.is-open {
    transform: rotate(-90deg);
  }
`

export const UIArrow = props => {
  return props.topToBottom ? <ArrowTopBottom className={props.isOpen ? 'is-open' : ''} /> : <Arrow />
}

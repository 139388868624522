import { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { translate, handleKeyUp, addGetParam, getURLSearchParams, assetsPath } from '../services/tools'
import history from '../history'
import { formatDate } from '../utils/tools'
import axios from '../middlewares/axios'

const { REACT_APP_API_PROXY_URL } = process.env

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  opacity: 1;
`

const PrintButton = styled.button`
  padding: 5px !important;
  margin-top: 3px;
  border: none;
`

const Label = styled.label`
  margin: 3px 5px;
`

const Checkbox = styled.input``

const RouteCalculationPrintPopup = () => {
  const [displaySummary, setDisplaySummary] = useState(true)
  const [openDisruptions, setOpenDisruptions] = useState(true)
  const [openWalkingSections, setOpenWalkingSections] = useState(true)
  const [isPrinting, setIsPrinting] = useState(false)
  const [openStops, setOpenStops] = useState(false)
  const [displayMap, setDisplayMap] = useState(true)
  const datenow = formatDate(new Date(), 'ymdThm')
  const language = useSelector(state => state.app.language)

  const handlePrintButton = () => {
    setIsPrinting(true)

    const params = getURLSearchParams(history.location)

    if (params.date === 'now') {
      params.date = datenow
    }

    const searchParams = addGetParam(params, {
      displaySummary: displaySummary ? 'true' : 'false',
      openDisruptions: openDisruptions ? 'true' : 'false',
      openWalkingSections: openWalkingSections ? 'true' : 'false',
      openStops: openStops ? 'true' : 'false',
      displayMap: displayMap ? 'true' : 'false',
      lang: language,
      type: 'route-calculation',
      size: 'large',
    })

    axios
      .get(`/api/print${searchParams}`)
      .then(response => {
        if (response.data && response.data.status === 'done') {
          const element = document.createElement('a')

          element.setAttribute(
            'href',
            `${
              REACT_APP_API_PROXY_URL
                ? REACT_APP_API_PROXY_URL.slice(-1) === '/'
                  ? REACT_APP_API_PROXY_URL
                  : REACT_APP_API_PROXY_URL + '/'
                : `${window.location.origin}/`
            }api/file?name=${response.data.filename}&folder=${response.data.dir}&ext=pdf`,
          )
          element.click()
          element.onclick = () => element.remove()
          setIsPrinting(false)
        } else {
          console.log('Route calculation print server error')
          setIsPrinting(false)
        }
      })
      .catch(e => {
        console.log('Route calculation print error : ', e)
        setIsPrinting(false)
      })
  }

  return (
    <Container>
      <Label htmlFor="openDisruptions" className="lc-openDisruptions">
        <Checkbox
          type="checkbox"
          name="openDisruptions"
          id="openDisruptions"
          value={openDisruptions}
          checked={openDisruptions ? 'checked' : ''}
          onChange={() => setOpenDisruptions(!openDisruptions)}
          onKeyUp={e => handleKeyUp(e, () => setOpenDisruptions(!openDisruptions))}
        />
        {translate('popup-print-open-disruptions')}
      </Label>
      <Label htmlFor="openWalkingSections" className="lc-openWalkingSections">
        <Checkbox
          type="checkbox"
          name="openWalkingSections"
          id="openWalkingSections"
          value={openWalkingSections}
          checked={openWalkingSections ? 'checked' : ''}
          onChange={() => setOpenWalkingSections(!openWalkingSections)}
          onKeyUp={e => handleKeyUp(e, () => setOpenWalkingSections(!openWalkingSections))}
        />
        {translate('popup-print-walking-sections')}
      </Label>
      <Label htmlFor="displaySummary" className="lc-displaySummary">
        <Checkbox
          type="checkbox"
          name="displaySummary"
          id="displaySummary"
          value={displaySummary}
          checked={displaySummary ? 'checked' : ''}
          onChange={() => setDisplaySummary(!displaySummary)}
          onKeyUp={e => handleKeyUp(e, () => setDisplaySummary(!displaySummary))}
        />
        {translate('popup-print-display-summary')}
      </Label>

      <Label htmlFor="displayMap" className="lc-displayMap">
        <Checkbox
          type="checkbox"
          name="displayMap"
          id="displayMap"
          value={displayMap}
          checked={displayMap ? 'checked' : ''}
          onChange={() => setDisplayMap(!displayMap)}
          onKeyUp={e => handleKeyUp(e, () => setDisplayMap(!displayMap))}
        />
        {translate('popup-print-display-map')}
      </Label>
      <Label htmlFor="openStops" className="lc-openStops">
        <Checkbox
          type="checkbox"
          name="openStops"
          id="openStops"
          value={openStops}
          checked={openStops ? 'checked' : ''}
          onChange={() => setOpenStops(!openStops)}
          onKeyUp={e => handleKeyUp(e, () => setOpenStops(!openStops))}
        />
        {translate('popup-print-open-stops')}
      </Label>
      {isPrinting ? (
        <div className="lc-loading" data-lc-loading>
          <img src={assetsPath('/assets/images/loading.gif')} width={30} alt={translate('loading')} />
        </div>
      ) : (
        <PrintButton
          onClick={() => handlePrintButton()}
          onKeyUp={e => handleKeyUp(e, () => handlePrintButton())}
          className="lc-button">
          {translate('popup-print-button')}
        </PrintButton>
      )}
    </Container>
  )
}

export default RouteCalculationPrintPopup

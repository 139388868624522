import { useEffect, useState } from 'react'
import axios from '../../middlewares/axios'
import { useRouter } from '../../hooks/useRouter'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { UILine } from './UILine'
import { assetsPath, handleKeyUp, isActiveModule, translate, unique } from '../../services/tools'
import UIStopScheduleTime from './UIStopScheduleTime'
import UIStopPmr from './UIStopPmr'
import Collapse from '@kunukn/react-collapse'
import UIDisruptedLineOverlay from './UIDisruptedLineOverlay'
import { actionSetLinesSchedules } from '../../actions/board'
import exportedSCSS from '../../scss/app.scss'
import { dateIsAtLeastTomorrow, formatDate, navitiaDateToDate } from '../../utils/tools'

const { REACT_APP_SCHEDULES_EXTENDS } = process.env
const { primarycolor, secondarycolor, borderXS } = exportedSCSS

const Groups = styled.div``

const Group = styled.div`
  padding: 10px 5px;
  &:not(:last-child) {
    border-bottom: solid ${() => borderXS} 1px;
  }
`

const GroupTitle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0px 15px;
`

const GroupName = styled.div`
  flex-grow: 1;
`

const GroupArrow = styled.img`
  height: 20px;
  transition: all ease-in 150ms;
  width: 20px;
  transform: rotate(-90deg);
  ${props => (props.isOpen ? `transform: rotate(0deg);` : '')}
`

const SmallerGroupArrow = styled.img`
  height: 15px;
  width: 15px;
  /* transform: rotate(-90deg); */
`

const Lines = styled.div`
  cursor: default;
`

const Line = styled.div`
  display: flex;
  gap: 15px;
  margin: 15px;
  &:not(:last-child) {
    padding-bottom: 15px;
    border-bottom: solid ${() => borderXS} 1px;
  }
  &:last-child {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .lc-uiline {
    width: 30px !important;
    height: 30px !important;
    margin-top: 7px;
  }
`

const Destinations = styled.div`
  flex-grow: 1;
  font-size: 0.9em;
`

const Destination = styled.div`
  cursor: pointer;
  &:not(:first-child) {
    margin-top: 5px;
  }
  display: flex;
  align-items: center;
  &:hover {
    background-color: ${() => secondarycolor};
  }
  padding: 5px;
  border-radius: 5px;
`

const DestinationName = styled.div`
  font-weight: 700;
  div {
    display: inline-block;
    height: 12px !important;
    width: 12px !important;
  }
`

const DestinationContent = styled.div`
  flex-grow: 1;
`

const Schedules = styled.div`
  display: flex;
  flex-direction: column;
  border-left: solid ${() => primarycolor} 2px;
  padding-left: 10px;
  font-weight: 500;

  .lc-schedule {
    img {
      width: 10px;
      margin-left: 7px;
    }
  }
`

const LastUpdatedSchedule = styled.div`
  font-size: 0.85em;
  padding: 10px 0px 10px 20px;
  border-top: solid ${() => borderXS} 1px;
`

const UILinesByArea = props => {
  const { linesList, linesListStop } = props
  const [schedules, setSchedules] = useState([])
  const [loading, setLoading] = useState(true)
  const router = useRouter()
  const { lines, stops, configApp, language, linesModes } = useSelector(state => state.app)
  const [openedGroup, setOpenedGroup] = useState(configApp?.display_open_linesmodes ? [] : '')
  const dispatch = useDispatch()
  const [timeintervalId, setTimeIntervalId] = useState(false)
  const [requestDate, setRequestDate] = useState(false)

  useEffect(() => {
    return () => {
      if (timeintervalId) {
        clearInterval(timeintervalId)
      }
    }
  }, [timeintervalId])

  useEffect(() => {
    if (linesModes?.length > 0) {
      if (configApp?.display_open_linesmodes) {
        setOpenedGroup(linesModes.map(line => line.name))
      } else {
        for (const lm of linesModes) {
          if (linesList.filter(l => lm.modes.includes(l.mode)).length > 0) {
            setOpenedGroup(lm.name)
            break
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [linesModes, linesListStop])

  useEffect(() => {
    getSchedules()
    if (configApp?.interval_line_schedules) {
      const intervalId = setInterval(() => {
        getSchedules()
      }, configApp?.interval_line_schedules)

      setTimeIntervalId(intervalId)
    }
    // eslint-disable-next-line
  }, [linesList])

  const getSchedules = () => {
    axios
      .get(
        `/api/schedules?area=${router.query.stop_area}${
          REACT_APP_SCHEDULES_EXTENDS ? `&extend=${+REACT_APP_SCHEDULES_EXTENDS}` : ''
        }&count=10`,
      )
      .then(response => {
        if (response?.data?.length > 0) {
          setSchedules(response.data)
          dispatch(actionSetLinesSchedules(response.data))
        }
      })
      .catch(e => {
        console.log('Error in get schedules : ', e)
      })
      .finally(() => {
        setLoading(false)
        setRequestDate(formatDate(new Date(), 'full-with-time', language))
      })
  }

  const handleClickLine = (line, route) => {
    let from = ''
    if (isActiveModule('around')) {
      from = '&from=around' + (router.query.line ? `&fromline=${router.query.line}` : '')
    } else if (isActiveModule('route-calculation')) {
      from = '&from=route-calculation'
    } else if (router.pathname === '/') {
      from = '&from=entrance'
    } else if (isActiveModule('lines') && router.query.stop_area && !router.query.current) {
      from = `&from=${router.query.stop_area}`
    }
    router.push(`/lines?current=${line.id}_${route.direction_id}&stop=${router.query.stop_area}${from}`)
  }

  const handleCollapse = lm => {
    if (configApp?.display_open_linesmodes) {
      const actualIndex = openedGroup.indexOf(lm.name)

      setOpenedGroup(
        openedGroup.includes(lm.name)
          ? openedGroup.filter((_, index) => index !== actualIndex)
          : prevState => [...prevState, lm.name],
      )
    } else {
      setOpenedGroup(openedGroup === lm.name ? '' : lm.name)
    }
  }

  return (
    <>
      <Groups>
        {linesModes
          .filter(lm => linesList.filter(l => lm.modes.includes(l.mode)).length > 0)
          .map(lm => (
            <Group key={`line-mode-${lm.id}`}>
              <GroupTitle
                onClick={() => handleCollapse(lm)}
                onKeyUp={e =>
                  handleKeyUp(e, () => {
                    handleCollapse(lm)
                  })
                }
                role="button"
                tabIndex="0"
                aria-label={translate(
                  'aria-stop-area-lines-mode-going-through',
                  false,
                  {
                    key: 'mode',
                    value: translate(lm.name, false),
                  },
                  {
                    key: 'stop',
                    value: linesListStop,
                  },
                )}>
                <GroupName>{translate(lm.name)}</GroupName>
                <GroupArrow
                  className="lc-closed"
                  src={assetsPath('/assets/images/v.svg')}
                  isOpen={configApp?.display_open_linesmodes ? openedGroup.includes(lm.name) : openedGroup === lm.name}
                />
              </GroupTitle>
              {loading ? (
                <div className="lc-loading" data-lc-loading>
                  <img src={assetsPath('/assets/images/loading.gif')} alt={translate('loading')} />
                </div>
              ) : (
                <Collapse
                  isOpen={configApp?.display_open_linesmodes ? openedGroup.includes(lm.name) : openedGroup === lm.name}>
                  <Lines className="lc-lines-going-through-stoparea">
                    {linesList
                      ?.map(l => lines.find(lf => lf.id === l.id))
                      .filter(l => l && lm.modes.includes(l.mode))
                      .map(line => (
                        <Line key={`line-by-area-${line.id}`} className="lc-lines-going-through-stoparea-line">
                          <div className="">
                            <UIDisruptedLineOverlay line={line} styleLine={process.env.REACT_APP_LINES_MAIN_TYPE}>
                              <UILine line={line} image={process.env.REACT_APP_LINES_MAIN_TYPE?.includes('image')} />
                            </UIDisruptedLineOverlay>
                          </div>
                          <Destinations>
                            {lines
                              .find(l => l.id === line.id)
                              ?.routes.filter(route =>
                                stops.find(
                                  s =>
                                    s.stop_area === router.query.stop_area &&
                                    s.lines.find(r => r.route_id === route.route_id),
                                ),
                              )
                              ?.map(route => {
                                const routeDestinations = unique(
                                  schedules
                                    ?.find(s => s.route_id === route.route_id)
                                    ?.date_times?.map(dt => dt.destination) || [],
                                )

                                return routeDestinations?.map((rd, rdi) => {
                                  return (
                                    <Destination
                                      key={route.route_id + '_' + rdi}
                                      onClick={() => handleClickLine(line, route)}
                                      onKeyUp={e => handleKeyUp(e, handleClickLine(line, route))}
                                      tabIndex="0"
                                      role="button"
                                      aria-label={translate(
                                        'aria-lines-stop-area-open-line-direction',
                                        false,
                                        { key: 'line', value: line.code },
                                        { key: 'direction', value: rd },
                                      )}>
                                      <DestinationContent>
                                        <DestinationName>
                                          {translate('schedules-to')} {rd}
                                          <UIStopPmr
                                            pmr={
                                              stops.find(
                                                s =>
                                                  s.stop_area === router.query.stop_area &&
                                                  s.lines.find(r => r.route_id === route.route_id),
                                              )?.pmr
                                            }
                                          />
                                        </DestinationName>
                                        <Schedules>
                                          {schedules.find(s => s.route_id === route.route_id)
                                            ?.additional_informations === 'terminus' ? (
                                            <div>{translate('stop-terminus')}</div>
                                          ) : schedules
                                              .find(s => s.route_id === route.route_id)
                                              ?.date_times?.filter(dt => dt.destination === rd)?.length > 0 ? (
                                            schedules
                                              .find(s => s.route_id === route.route_id)
                                              ?.date_times.filter(dt => dt.destination === rd)
                                              .filter((_, dtindex) => dtindex < 2)
                                              .map((dt, dtindex) => (
                                                <UIStopScheduleTime
                                                  key={`schedule-${dtindex}-${rdi}`}
                                                  configApp={configApp}
                                                  language={language}
                                                  schedule={{
                                                    time: dt.date_time,
                                                    realtime: dt.data_freshness === 'realtime',
                                                    equipments: dt.equipments,
                                                    extend:
                                                      REACT_APP_SCHEDULES_EXTENDS ||
                                                      (configApp.display_date_on_other_day_schedules &&
                                                        dateIsAtLeastTomorrow(navitiaDateToDate(dt.date_time)))
                                                        ? true
                                                        : false,
                                                  }}
                                                />
                                              ))
                                          ) : (
                                            <div>{translate('no-schedules')}</div>
                                          )}
                                        </Schedules>
                                      </DestinationContent>
                                      <SmallerGroupArrow
                                        className="lc-closed"
                                        src={assetsPath('/assets/images/arrow-calendar.svg')}
                                        isOpen={false}
                                      />
                                    </Destination>
                                  )
                                })
                              })}
                          </Destinations>
                        </Line>
                      ))}
                  </Lines>
                </Collapse>
              )}
            </Group>
          ))}
      </Groups>
      {requestDate ? (
        <LastUpdatedSchedule className="lc-stop-area-lines-lastest-refresh-schedules">
          {translate('lines-date-update-schedules', false, { key: 'date', value: requestDate })}
        </LastUpdatedSchedule>
      ) : null}
    </>
  )
}

export default UILinesByArea

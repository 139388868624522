import StopInterface from './StopInterface'

export default class LineInterface {
  constructor(line, project) {
    switch (project) {
      case 'bordeaux-tbm':
      case 'bordeaux-tbm-azure':
        this.id = line.id
        this.code = line.code
        this.name = line.name
        this.color = line.color
        this.text = line.textColor
        this.mode = 'commercial_mode:' + line.type
        this.cat = line.type.toLowerCase()
        this.network = project
        this.stops = line.routes
          .map((r, index) => {
            const direction = index === 0 ? 'f' : 'b'

            return r.stopPoints.map(s => {
              const index = (
                Array.isArray(r.stopPointOrder) ? r.stopPointOrder : Object.keys(r.stopPointOrder)
              ).indexOf(s.id)

              s.terminus =
                index === 0 ||
                index ===
                  (Array.isArray(r.stopPointOrder) ? r.stopPointOrder : Object.keys(r.stopPointOrder)).length - 1
              let stop = new StopInterface(s, this, project)

              stop.lines = [
                {
                  id: this.id,
                  code: this.code,
                  name: this.name,
                  mode: this.mode,
                  cat: this.cat,
                  network: this.network,
                  position: 1,
                  direction_id: direction,
                  direction: r.end,
                  route_id: r.id,
                  stop_id: stop.id,
                },
              ]
              return stop
            })
          })
          .flat()
        this.routes = line.routes.map((r, index) => {
          const direction = index === 0 ? 'f' : 'b'

          return {
            id: this.code + '_' + this.network + '_' + direction,
            name: r.name,
            direction: r.end,
            direction_id: direction,
            route_id: r.id,
          }
        })
        break
      default:
        break
    }
  }
}

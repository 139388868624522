import { translate, assetsPath } from '../services/tools'
import styled from 'styled-components'
import { formatDate } from '../utils/tools'

const RealTimeImg = styled.img`
  height: 8px;
  width: 8px;
  margin-right: 3px;
`

export default class CustomPopupInterface {
  constructor(data, project) {
    switch (project) {
      case 'bordeaux-tbm': {
        const displayWaitingTime = schedule => {
          const wt = schedule.waittime.split(':')
          const hours = parseInt(wt[0])
          const minutes = parseInt(wt[1])
          const secondes = parseInt(wt[2])

          if (hours === 0 && minutes === 0 && secondes >= 0) {
            return translate('vehicle-close', false)
          } else if (hours === 0 && minutes > 0) {
            return minutes + ' min'
          } else {
            const d = new Date(schedule.arrival)

            return 'à ' + formatDate(d, 'h:m')
          }
        }

        this.content = (
          <table className="lc-custom-popup-schedules">
            <thead>
              <tr>
                <th>Ligne</th>
                <th>Destination</th>
                <th>Prochains passages</th>
              </tr>
            </thead>
            <tbody>
              {data.schedules === undefined || (data.schedules && data.schedules.length === 0) ? (
                <tr>
                  <td className="lc-line-code">
                    <img
                      src={assetsPath('/assets/images/lines/' + data.line.code + '.svg')}
                      alt={data.line.code}
                      aria-hidden="true"
                    />
                  </td>
                  <td colSpan="2">{translate('no-schedules')}</td>
                </tr>
              ) : (
                data.schedules?.map((schedule, index) => (
                  <tr key={'schedule-' + index}>
                    <td className="lc-line-code">
                      <img
                        src={assetsPath('/assets/images/lines/' + data.line.code + '.svg')}
                        alt={data.line.code}
                        aria-hidden="true"
                      />
                    </td>
                    <td>{schedule.destination_name}</td>
                    <td>
                      {schedule.realtime === '1' && (
                        <RealTimeImg
                          src={assetsPath('/assets/images/realtime.gif')}
                          alt={translate('realtime-gif-alt')}
                          title={translate('realtime-gif-title')}
                        />
                      )}{' '}
                      {displayWaitingTime(schedule)}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        )

        break
      }
      case 'bordeaux-tbm-azure': {
        this.content = null
        break
        // const displayWaitingTime = schedule => {
        //   const d = new Date(schedule.departure)
        //   const dFromNow = dateFromNow(d)

        //   const { hours, minutes, seconds } = dFromNow

        //   if (hours === 0 && minutes === 0 && seconds >= 0) {
        //     return translate('vehicle-close', false)
        //   } else if (hours === 0 && minutes > 0) {
        //     return minutes + ' min'
        //   } else {
        //     return 'à ' + formatDate(d, 'h:m')
        //   }
        // }

        // this.content = (
        //   <table className="lc-custom-popup-schedules">
        //     <thead>
        //       <tr>
        //         <th>Ligne</th>
        //         <th>Destination</th>
        //         <th>Prochains passages</th>
        //       </tr>
        //     </thead>
        //     <tbody>
        //       {data.schedules === undefined || (data.schedules && data.schedules.length === 0) ? (
        //         <tr>
        //           <td className="lc-line-code">
        //             <img
        //               src={assetsPath('/assets/images/lines/' + data.line.code + '.svg')}
        //               alt={data.line.code}
        //               aria-hidden="true"
        //             />
        //           </td>
        //           <td colSpan="2">{translate('no-schedules')}</td>
        //         </tr>
        //       ) : (
        //         data.schedules?.map((schedule, index) => (
        //           <tr key={'schedule-' + index}>
        //             <td className="lc-line-code">
        //               <img
        //                 src={assetsPath('/assets/images/lines/' + data.line.code + '.svg')}
        //                 alt={data.line.code}
        //                 aria-hidden="true"
        //               />
        //             </td>
        //             <td>{schedule.route.terminus}</td>
        //             <td>
        //               {schedule.isRealTime && (
        //                 <RealTimeImg
        //                   src={assetsPath('/assets/images/realtime.gif')}
        //                   alt={translate('realtime-gif-alt')}
        //                   title={translate('realtime-gif-title')}
        //                 />
        //               )}{' '}
        //               {displayWaitingTime(schedule)}
        //             </td>
        //           </tr>
        //         ))
        //       )}
        //     </tbody>
        //   </table>
        // )

        // break
      }
      default:
        break
    }
  }
}

import { createStore, applyMiddleware, combineReducers } from 'redux'
import app from './reducers/app'
import board from './reducers/board'
import login from './reducers/login'
import map from './reducers/map'
import modal from './reducers/modal'
import modalGeneric from './reducers/modalGeneric'
import network from './reducers/network'
import tourismPartners from './reducers/tourismPartners'
import mapMiddleware from './middlewares/map'
import boardMiddleware from './middlewares/board'

const enableBatch = reducer => {
  return function batchingReducer(state, action) {
    switch (action.type) {
      case 'BATCH':
        return action.actions.reduce(batchingReducer, state)
      default:
        return reducer(state, action)
    }
  }
}

const reducers = combineReducers({
  app,
  board,
  map,
  modal,
  modalGeneric,
  network,
  tourismPartners,
  login,
})

export const appStore = createStore(enableBatch(reducers), applyMiddleware(boardMiddleware(), mapMiddleware()))

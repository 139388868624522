import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { debug } from '../services/tools'
import styled from 'styled-components'
import tweens from 'tween-functions'
import exportedSCSS from '../scss/app.scss'

const { primarycolor } = exportedSCSS

const ScrollElement = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 499;
  opacity: ${props => (props.show ? 1 : 0)};
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
`

export const ScrollToTop = ({ duration, easing, showAt, style, top }) => {
  const [show, setShow] = useState(false)

  const data = {
    startValue: 0,
    currentTime: 0,
    startTime: null,
    id: null,
  }

  useEffect(() => {
    /**
     * Evaluate show/hide this component, depending on new position
     */
    function handleScroll() {
      if (window.pageYOffset > showAt) {
        if (!show) {
          setShow(true)
        }
      } else {
        if (show) {
          setShow(false)
        }
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }

    // eslint-disable-next-line
  }, [show])

  /**
   * Start scrolling animation frame
   */
  function handleClick() {
    debug({ message: 'Just clicked' }, 'info', 'click scroll')

    stopScrolling()

    data.startValue = window.pageYOffset
    data.currentTime = 0
    data.startTime = null
    data.id = window.requestAnimationFrame(scrollStep)
  }

  /**
   * Stop scrolling animation frame
   */
  function stopScrolling() {
    window.cancelAnimationFrame(data.id)
  }

  /**
   * Scrolling step for a given frame
   * @param {String} timestamp current frame timestamp
   */
  function scrollStep(timestamp) {
    if (!data.startTime) {
      data.startTime = timestamp
    }

    data.currentTime = timestamp - data.startTime

    // Calculate new position for the next animation frame with tween function
    const position = tweens[easing](data.currentTime, data.startValue, top, duration)

    if (window.pageYOffset <= top) {
      stopScrolling()
    } else {
      window.scrollTo(window.pageYOffset, position)
      data.id = window.requestAnimationFrame(scrollStep)
    }
  }

  return (
    <ScrollElement show={show} onClick={handleClick} style={style} className="lc-scrollToTop">
      &uarr;
    </ScrollElement>
  )
}

// Default props
ScrollToTop.defaultProps = {
  duration: 250,
  easing: 'easeInCubic',
  showAt: 60,
  style: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: 30,
    right: 30,
    width: 30,
    height: 30,
    borderRadius: '50%',
    cursor: 'pointer',
    backgroundColor: primarycolor,
    color: 'white',
    transitionDuration: '0.2s',
    transitionTimingFunction: 'linear',
    transitionDelay: '0s',
  },
  top: 0,
}

// Validation property types
ScrollToTop.propTypes = {
  topPosition: PropTypes.number,
  showAt: PropTypes.number,
  easing: PropTypes.oneOf([
    'linear',
    'easeInQuad',
    'easeOutQuad',
    'easeInOutQuad',
    'easeInCubic',
    'easeOutCubic',
    'easeInOutCubic',
    'easeInQuart',
    'easeOutQuart',
    'easeInOutQuart',
    'easeInQuint',
    'easeOutQuint',
    'easeInOutQuint',
    'easeInSine',
    'easeOutSine',
    'easeInOutSine',
    'easeInExpo',
    'easeOutExpo',
    'easeInOutExpo',
    'easeInCirc',
    'easeOutCirc',
    'easeInOutCirc',
    'easeInElastic',
    'easeOutElastic',
    'easeInOutElastic',
    'easeInBack',
    'easeOutBack',
    'easeInOutBack',
    'easeInBounce',
    'easeOutBounce',
    'easeInOutBounce',
  ]),
  duration: PropTypes.number, // in seconds
  style: PropTypes.object,
}

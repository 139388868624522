export const actionSetNetworkSliderMode = slider => ({
  type: 'SET_NETWORK_SLIDER_MODE',
  slider,
})

export const actionSetNetworkMapRef = mapRef => ({
  type: 'SET_NETWORK_MAP_REF',
  mapRef,
})

export const actionSetNetworkMapHeavyLines = lines => ({
  type: 'SET_NETWORK_MAP_HEAVY_LINES',
  lines,
})

export const actionSetNetworkTownsLines = lines => ({
  type: 'SET_NETWORK_TOWNS_LINES',
  lines,
})

export const actionSetNetwotkSelectedLine = line => ({
  type: 'SET_NETWORK_SELECTED_LINE',
  line,
})

export const actionSetNetworkSelectedTown = town => ({
  type: 'SET_NETWORK_SELECTED_TOWN',
  town,
})

export const actionSetNetworkSlider = slider => ({
  type: 'SET_NETWORK_DISPLAY_SLIDER',
  slider,
})

export const actionSetModesGroupPanel = (mode, open) => ({
  type: 'SET_MODES_GROUP_PANEL',
  mode,
  open,
})

export const actionSetModeLinesAccordeon = mode => ({
  type: 'SET_MODE_LINES_ACCORDEON',
  mode,
})

export const actionSetSliderUrlLeft = url => ({
  type: 'SET_SLIDER_URL_LEFT',
  url,
})

export const actionSetSliderUrlRight = url => ({
  type: 'SET_SLIDER_URL_RIGHT',
  url,
})

export const actionSetNetworkSelectedStop = stop => ({
  type: 'SET_NETWORK_SELECTED_STOP',
  stop,
})

import { useEffect } from 'react'
import useScreenOrientation from '../hooks/useScreenOrientation'
import { useDispatch } from 'react-redux'
import { actionSetOrientationChange } from '../actions/app'

export function ScreenOrientation({ children }) {
  const orientation = useScreenOrientation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actionSetOrientationChange(orientation))
  }, [orientation])

  return <div>{children(orientation)}</div>
}

import { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import { resize } from '../../services/tools'

export default function UIHeader({ headerDirection, header }) {
  const isMobile = useSelector(state => state.app.isMobile)

  useLayoutEffect(() => {
    resize(isMobile)
  }, [isMobile])

  return (
    <header
      className={
        (headerDirection === 'column' || isMobile ? 'lc-header-column' : 'lc-header-row') + (isMobile ? '-mobile' : '')
      }
      dangerouslySetInnerHTML={{ __html: header }}
    />
  )
}

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UILine } from './UILine'
import styled from 'styled-components'
import UIStopScheduleTime from './UIStopScheduleTime'
import UIStopPmr from './UIStopPmr'
import { handleKeyUp, isActiveModule, translate, unique, updatePopupPosition } from '../../services/tools'
import { buildLinesLabels } from '../../services/map'
import UIDisruptedLineOverlay from './UIDisruptedLineOverlay'
import { useRouter } from '../../hooks/useRouter'
import exportedSCSS from '../../scss/app.scss'
import { actionSetLinesSchedules } from '../../actions/board'
import axios from '../../middlewares/axios'

const { borderXS, secondarycolor, textColor } = exportedSCSS

const { REACT_APP_SCHEDULES_EXTENDS } = process.env

const Lines = styled.div`
  cursor: default;
  color: ${() => textColor};
`

const Line = styled.div`
  display: flex;
  gap: 5px;
  &:not(:first-child) {
    padding-top: 10px;
    border-top: solid ${() => borderXS} 1px;
    margin-top: 5px;
  }
  &:last-child {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .lc-uiline {
    width: 30px !important;
    height: 30px !important;
    margin-top: 7px;
  }
`

const Destinations = styled.div`
  flex-grow: 1;
  font-size: 0.9em;
`

const Destination = styled.div`
  &:not(:first-child) {
    margin-top: 0px;
  }
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  &:hover {
    background-color: ${props => (props.canClickLine ? secondarycolor : 'transparent')};
  }
  ${props =>
    props.canClickLine
      ? `
      cursor: pointer;
 `
      : ''}
`

const DestinationName = styled.div`
  white-space: ${props => (!props.isMobile ? 'nowrap' : '')};
  font-weight: 700;
  div {
    display: inline-block;
    height: 12px !important;
    width: 12px !important;
  }
`

const DestinationContent = styled.div`
  flex-grow: 1;
`

const Schedules = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0px 20px;

  .lc-schedule {
    img {
      width: 12px;
      margin-left: 5px;
    }
  }
`

const MoreSchedules = styled.div`
  font-size: 0.8em;
  cursor: pointer;
  margin: 0px ${props => (props.noLeftMargin ? '0px' : '7px')} 0px ${props => (props.noLeftMargin ? '0px' : '7px')};
  display: inline-block;
  text-wrap: nowrap;
  text-decoration-line: underline;
`

const UIPopupLinesByArea = props => {
  const { area, componentState, dataKey } = props
  const { configApp, language, component, lines, stops, isMobile } = useSelector(state => state.app)
  const { linesSchedules } = useSelector(state => state.board)
  const { openedMarker } = useSelector(state => state.map)
  const dispatch = useDispatch()
  const { options } = component.props
  const canClickLine = options?.features?.['change-line'] === false ? false : true
  const router = useRouter()

  useEffect(() => {
    setTimeout(() => {
      if (area?.ref?.leafletElement) {
        updatePopupPosition(area.ref.leafletElement, area)
      }
    })

    // eslint-disable-next-line
  }, [linesSchedules])

  useEffect(() => {
    if (isActiveModule('around') && openedMarker?.id === area.id && !(unique(area.lines, 'id')?.length > 2)) {
      axios
        .get(
          `/api/schedules?area=${area.id}${
            REACT_APP_SCHEDULES_EXTENDS ? `&extend=${+REACT_APP_SCHEDULES_EXTENDS}` : ''
          }&count=10`,
        )
        .then(response => {
          if (response?.data?.length > 0) {
            dispatch(actionSetLinesSchedules(response.data))
          }
        })
        .catch(e => {
          console.log('Error in get schedules : ', e)
        })
    }
    // eslint-disable-next-line
  }, [openedMarker, area])

  const handleClickLine = (e, line, route) => {
    if (canClickLine) {
      let from = ''
      if (isActiveModule('around')) {
        from = '&from=around' + (router.query.line ? `&fromline=${router.query.line}` : '')
      } else if (isActiveModule('route-calculation')) {
        from = '&from=route-calculation'
      } else if (router.pathname === '/') {
        from = '&from=entrance'
      } else if (isActiveModule('lines') && router.query.stop_area && !router.query.current) {
        from = `&from=${router.query.stop_area}`
      } else if (isActiveModule('lines') && router.query.current) {
        from = `&from=${router.query.current}`
      }
      router.push(`/lines?current=${line.id}_${route.direction_id}&stop=${area.id}${from}`)
    }
    e.stopPropagation()
    e.preventDefault()
    return false
  }

  const handleClickSeeSchedule = () => {
    router.push(
      `/lines?stop_area=${area.id}${
        isActiveModule('around') ? '&from=around' : router.pathname === '/' ? '&from=entrance' : ''
      }`,
    )
  }

  if (unique(area.lines, 'id')?.length > 2 || openedMarker?.id !== area.id) {
    return (
      <>
        {buildLinesLabels(componentState, area, dataKey)}
        {!isActiveModule('lines') && router.pathname !== '/' ? (
          <MoreSchedules onClick={() => handleClickSeeSchedule()}>
            {translate('popup-schedules-lines-see-more-schedules-area')}
          </MoreSchedules>
        ) : null}
      </>
    )
  }

  return (
    <Lines className="lc-lines-going-through-stoparea">
      {unique(area.lines, 'id')?.map(line => (
        <Line key={`line-by-area-${line.id}`} className="lc-lines-going-through-stoparea-line">
          <div className="">
            <UIDisruptedLineOverlay line={line} styleLine={process.env.REACT_APP_LINES_MAIN_TYPE}>
              <UILine line={line} image={process.env.REACT_APP_LINES_MAIN_TYPE?.includes('image')} />
            </UIDisruptedLineOverlay>
          </div>
          <Destinations>
            {lines
              .find(l => l.id === line.id)
              ?.routes.map(route => {
                const routeDestinations = unique(
                  linesSchedules?.find(s => s.route_id === route.route_id)?.date_times?.map(dt => dt.destination) || [],
                )

                return routeDestinations?.map((rd, rdi) => {
                  return (
                    <Destination
                      key={route.route_id + '_' + rdi}
                      onClick={e => handleClickLine(e, line, route)}
                      onKeyUp={e => handleKeyUp(e, handleClickLine(e, line, route))}
                      tabIndex="0"
                      role="button"
                      aria-label={translate(
                        'aria-lines-stop-area-open-line-direction',
                        false,
                        { key: 'line', value: line.code },
                        { key: 'direction', value: rd },
                      )}
                      canClickLine={canClickLine}>
                      <DestinationContent>
                        <DestinationName isMobile={isMobile}>
                          {translate('schedules-to')} {rd}
                          <UIStopPmr
                            pmr={
                              stops.find(
                                s => s.stop_area === area.id && s.lines.find(r => r.route_id === route.route_id),
                              )?.pmr
                            }
                          />
                        </DestinationName>
                        <Schedules>
                          {linesSchedules
                            .find(s => s.route_id === route.route_id)
                            ?.date_times?.filter(dt => dt.destination === rd)?.length == 0 &&
                          linesSchedules.find(s => s.route_id === route.route_id)?.additional_informations ===
                            'terminus' ? (
                            <div>{translate('stop-terminus')}</div>
                          ) : linesSchedules
                              .find(s => s.route_id === route.route_id)
                              ?.date_times?.filter(dt => dt.destination === rd)?.length > 0 ? (
                            linesSchedules
                              .find(s => s.route_id === route.route_id)
                              ?.date_times?.filter(dt => dt.destination === rd)
                              ?.filter((_, rdi) => rdi < 2)
                              ?.map((dt, dtindex) => (
                                <UIStopScheduleTime
                                  key={`schedule-${dtindex}`}
                                  configApp={configApp}
                                  language={language}
                                  schedule={{
                                    time: dt.date_time,
                                    realtime: dt.data_freshness === 'realtime',
                                    equipments: dt.equipments,
                                    extend: process.env.REACT_APP_SCHEDULES_EXTENDS,
                                  }}
                                />
                              ))
                          ) : (
                            <div>{translate('no-schedules')}</div>
                          )}
                        </Schedules>
                      </DestinationContent>
                    </Destination>
                  )
                })
              })}
          </Destinations>
        </Line>
      ))}
      {!isActiveModule('lines') && router.pathname !== '/' ? (
        <MoreSchedules onClick={() => handleClickSeeSchedule()} noLeftMargin={true}>
          {translate('popup-schedules-lines-see-more-schedules-area')}
        </MoreSchedules>
      ) : null}
    </Lines>
  )
}

export default UIPopupLinesByArea

import { envVarToBool, translate } from '../../services/tools'

const { REACT_APP_SHOW_PMR, REACT_APP_SHOW_PMR_TRANSLATION } = process.env

const UIStopPmr = props => {
  const { pmr, displayon, whitePicto } = props

  if (['pmr', 'both'].includes(REACT_APP_SHOW_PMR) && pmr === true) {
    if (displayon === 'popup' && envVarToBool(REACT_APP_SHOW_PMR_TRANSLATION)) {
      return (
        <div className="lc-pmr-text">
          <div
            className={`lc-is-pmr${whitePicto ? ' lc-white' : ''}`}
            aria-label={`${translate('stop', false)} ${translate('aria-lines-stop-pmr', false)}`}
          />
          <div>{translate('stop-pmr')}</div>
        </div>
      )
    } else {
      return (
        <div
          className={`lc-is-pmr${whitePicto ? ' lc-white' : ''}`}
          aria-label={`${translate('stop', false)} ${translate('aria-lines-stop-pmr', false)}`}
        />
      )
    }
  }

  if (['no-pmr', 'both'].includes(REACT_APP_SHOW_PMR) && pmr === false) {
    if (displayon === 'popup' && envVarToBool(REACT_APP_SHOW_PMR_TRANSLATION)) {
      return (
        <div className="lc-pmr-text">
          <div className="lc-is-no-pmr" />
          <div>{translate('stop-no-pmr')}</div>
        </div>
      )
    } else {
      return <div className="lc-is-no-pmr" />
    }
  }

  return <></>
}

export default UIStopPmr

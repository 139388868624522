import { useSelector, useDispatch } from 'react-redux'
import { message } from '../../services/message'
import { getLine, handleKeyUp, translate, assetsPath, isActiveModule } from '../../services/tools'
import { luminance } from 'luminance-js'
import history from '../../history'
import { actionBuildComplementaryLines } from '../../actions/withRedux'
import { sortBy } from '../../utils/leaflet/tools'
import UIDisruptedLineOverlay from './UIDisruptedLineOverlay'

const { REACT_APP_LINES_TYPE_EXCEPTIONS, REACT_APP_LINES_MAIN_TYPE } = process.env

const UIIntersecPopup = props => {
  const propsLines = props.lines
  const popup = props.popup
  const { lock, linesModes, size, lines, modules } = useSelector(state => state.app)
  let styleLine = REACT_APP_LINES_MAIN_TYPE ? REACT_APP_LINES_MAIN_TYPE : 'color'
  let prefixNetwork = false
  const gridRows = Math.ceil(propsLines.length / 2)
  const canClickLine = !lock
  const dispatch = useDispatch()

  const handleClickLine = line => {
    message({ clicked: 'line', id: line.id })

    dispatch(actionBuildComplementaryLines())

    if (popup) {
      popup.remove()
    }

    if (isActiveModule('around')) {
      history.push({
        pathname: '/around',
        search: '?line=' + line.id,
      })
    } else if (modules.find(m => m.id === 'lines')) {
      history.push({
        pathname: '/lines',
        search:
          '?current=' +
          line.id +
          (history.location.pathname.length > 1 ? '&from=' + history.location.pathname.substring(1) : ''),
      })
    } else if (modules.find(m => m.id === 'multimobilities')) {
      const multimobilities = modules.find(m => m.id === 'multimobilities')
      const tabLines = multimobilities.mobilities.find(m => m.data.map(d => d.type).includes('lines'))

      if (tabLines) {
        history.push({
          pathname: '/multimobilities',
          search: '?mob=' + tabLines.id + '&current=' + line.id,
        })
      }
    } else {
      console.warn('No module with lines')
    }
  }

  const handleMouseEnter = line => {
    dispatch(actionBuildComplementaryLines(line))
  }

  const handleMouseLeave = () => {
    dispatch(actionBuildComplementaryLines())
  }

  return (
    <div className="lc-infobox">
      <div className="lc-infobox-title">{translate('lignes-through-this-area')}</div>
      <div className="lc-infobox-content">
        <div
          className={`lc-infobox-lines lc-${size}${
            styleLine.includes('WithDirection') ? ' lc-line-with-direction' : ''
          }${canClickLine ? ' lc-interactive' : ''}`}
          style={
            styleLine.includes('WithRouteDirection')
              ? {
                  gridTemplateRows: `repeat(${gridRows}, 1fr)`,
                }
              : {}
          }>
          {sortBy(
            propsLines.map(line => getLine(lines, line)),
            'position',
          ).map(line => {
            if (REACT_APP_LINES_TYPE_EXCEPTIONS) {
              const exceptions = JSON.parse(REACT_APP_LINES_TYPE_EXCEPTIONS)

              const foundExceptedLine = exceptions.find(
                e => e.lines?.includes(line.id) || e.networks?.includes(line.network),
              )

              if (foundExceptedLine) {
                styleLine = foundExceptedLine.type
                prefixNetwork = foundExceptedLine.prefixNetwork === true
              }
            }

            switch (styleLine) {
              case 'modeWithDirection': {
                const lineMode = linesModes.find(mode => mode.modes.includes(line.mode))

                return (
                  <div
                    className="lc-attribute-line"
                    key={line.id}
                    onClick={() => handleClickLine(line)}
                    onKeyUp={e => handleKeyUp(e, () => handleClickLine(line))}
                    onMouseEnter={() => handleMouseEnter(line)}
                    onMouseLeave={() => handleMouseLeave()}
                    role={canClickLine ? 'button' : 'img'}
                    tabIndex="0"
                    aria-label={translate('aria-line', false, { key: 'code', value: line.code })}>
                    <UIDisruptedLineOverlay line={line} styleLine={styleLine} inpopup={true}>
                      <div
                        className="lc-line lc-mode"
                        style={{
                          background: '#' + line.color,
                          color: luminance('#' + line.color) > 0.5 ? '#333' : '#fff',
                        }}>
                        {lineMode.name}
                      </div>
                      <div className="lc-name">{line.name}</div>
                    </UIDisruptedLineOverlay>
                  </div>
                )
              }
              case 'codeWithDirection':
                return (
                  <div
                    className="lc-attribute-line"
                    key={line.id}
                    onClick={() => handleClickLine(line)}
                    onKeyUp={e => handleKeyUp(e, () => handleClickLine(line))}
                    onMouseEnter={() => handleMouseEnter(line)}
                    onMouseLeave={() => handleMouseLeave()}
                    role={canClickLine ? 'button' : 'img'}
                    tabIndex="0"
                    aria-label={translate('aria-line', false, { key: 'code', value: line.code })}>
                    <UIDisruptedLineOverlay line={line} styleLine={styleLine} inpopup={true}>
                      <div
                        className="lc-line lc-code"
                        style={{
                          background: '#' + line.color,
                          color: luminance('#' + line.color) > 0.5 ? '#333' : '#fff',
                        }}>
                        {line.code}
                      </div>
                      <div className="lc-name">{line.name}</div>
                    </UIDisruptedLineOverlay>
                  </div>
                )
              case 'imageWithRouteDirection':
              case 'image':
                return (
                  <div
                    className="lc-line"
                    key={line.id}
                    onClick={() => handleClickLine(line)}
                    onKeyUp={e => handleKeyUp(e, () => handleClickLine(line))}
                    onMouseEnter={() => handleMouseEnter(line)}
                    onMouseLeave={() => handleMouseLeave()}
                    role={canClickLine ? 'button' : 'img'}
                    tabIndex="0"
                    aria-label={translate('aria-line', false, { key: 'code', value: line.code })}>
                    <UIDisruptedLineOverlay line={line} styleLine={styleLine} inpopup={true}>
                      <img
                        src={assetsPath(
                          '/assets/images/lines/' + (prefixNetwork ? line.network + '-' : '') + line.code + '.svg',
                        )}
                        alt={line.code}
                        aria-hidden="true"
                      />
                    </UIDisruptedLineOverlay>
                    {styleLine === 'imageWithRouteDirection' && line.direction && (
                      <div className="lc-name">{line.direction}</div>
                    )}
                  </div>
                )
              case 'color':
                return (
                  <div
                    key={line.id}
                    className="lc-line"
                    onClick={() => handleClickLine(line)}
                    onKeyUp={e => handleKeyUp(e, () => handleClickLine(line))}
                    onMouseEnter={() => handleMouseEnter(line)}
                    onMouseLeave={() => handleMouseLeave()}
                    role={canClickLine ? 'button' : 'img'}
                    tabIndex="0"
                    aria-label={translate('aria-line', false, { key: 'code', value: line.code })}>
                    <UIDisruptedLineOverlay line={line} styleLine={styleLine} inpopup={true}>
                      <div
                        className="lc-line-code"
                        style={{
                          background: '#' + line.color,
                          color: luminance(line.color) > 0.5 ? '#333' : '#fff',
                        }}>
                        {line.code}
                      </div>
                    </UIDisruptedLineOverlay>
                  </div>
                )
              default:
                return ''
            }
          })}
        </div>
      </div>
    </div>
  )
}

export default UIIntersecPopup

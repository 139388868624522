import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { translate, isSystemUS, assetsPath } from '../services/tools'
import { formatDate, navitiaDateToDate } from '../utils/tools'
import { getLine, renderLine } from '../utils/leaflet/map'
import {
  Page,
  Header,
  Title,
  TitleText,
  TitleImg,
  Texts,
  Text,
  LeftSideContainer,
  HeaderPictosContainer,
  TitleTextContainer,
  MainContainer,
  BoldTextContainer,
  ContentImg,
  TextInBold,
} from './commun'
import { UILine } from '../components/styled/UILine'

const PrintTimetable = props => {
  const { language, configApp } = useSelector(state => state.app)
  const { timetableData, timetableHours } = useSelector(state => state.board)
  const { datas, page } = props
  const [component] = useState(datas.component)
  const [canPrint, setCanPrint] = useState(false)
  const now = new Date()
  const date = formatDate(now, isSystemUS(language) ? 'm/d/y' : 'd/m/y')
  const hours = formatDate(now, isSystemUS(language) ? 'h:m a' : 'h:m')

  const line = getLine(component, {
    id: page.line.includes('_f')
      ? page.line.substring(0, page.line.lastIndexOf('_f'))
      : page.line.includes('_b')
      ? page.line.substring(0, page.line.lastIndexOf('_b'))
      : page.line,
  })

  line.direction_id = page.line.slice(-1)

  useEffect(() => {
    if (timetableData) {
      // Check in the DOM if an lc-timetable element is present
      const timetable = document.querySelector('.lc-timetable-stop')

      if (timetable) {
        setTimeout(() => {
          const destinations = document.querySelectorAll('.lc-otherDirections')
          const append = []

          for (const dest of destinations) {
            if (append.indexOf(dest.innerHTML) === -1) {
              document.querySelector('.lc-scroll').append(dest)
              dest.style.marginLeft = '20px'
              append.push(dest.innerHTML)
            } else {
              dest.remove()
            }
          }
        })
      }

      setCanPrint(true)
    }

    // eslint-disable-next-line
  }, [timetableData, timetableHours])

  return (
    <Page className="lc-print-page">
      <Header>
        <Title className="lc-print-title">
          {configApp.new_print_design ? (
            <>
              <LeftSideContainer>
                <HeaderPictosContainer>
                  <img
                    className="lc-cat-picto"
                    src={assetsPath(`/assets/images/modes/${line.cat}.svg`)}
                    alt={line.cat}
                    aria-hidden="true"
                  />
                  <UILine line={line} image={'image'} width="80px" height="80px" />
                </HeaderPictosContainer>
                <TitleTextContainer>
                  <TitleText className="lc-print-title-text">{translate('lc-print-title')}</TitleText>
                  <Text className="journey-date">
                    {translate('journey-date', false, {
                      key: 'date',
                      value: formatDate(navitiaDateToDate(`${page.date}T1000`), 'full-with-year', language),
                    })}
                  </Text>
                </TitleTextContainer>
              </LeftSideContainer>
              <TitleImg className="lc-print-title-image" image="block-logo" />
            </>
          ) : (
            <>
              <TitleImg className="lc-print-title-image" />
              <TitleText className="lc-print-title-text">
                {translate('print-page-timetable-title', false, {
                  key: 'date',
                  value: formatDate(navitiaDateToDate(`${page.date}T1000`), 'full-with-year', language),
                })}
              </TitleText>
            </>
          )}
        </Title>
      </Header>
      {configApp.new_print_design ? (
        <MainContainer>
          <BoldTextContainer>
            <ContentImg className="lc-print-title-image" image="picto-timetable" />
            <TextInBold>{translate('timetable')}</TextInBold>
          </BoldTextContainer>
          {timetableData && renderLine(component, line, true, { timetableData, timetableHours })}
          {canPrint && <div id="can-print"></div>}
          <Texts>
            <Text>
              {translate('print-page-date', false, { key: 'date', value: date }, { key: 'hours', value: hours })}
            </Text>
            <Text dangerouslySetInnerHTML={{ __html: translate('print-page-check-website') }}></Text>
          </Texts>
        </MainContainer>
      ) : (
        <>
          <Texts>
            <Text>
              {translate('print-page-date', false, { key: 'date', value: date }, { key: 'hours', value: hours })}
            </Text>
            <Text dangerouslySetInnerHTML={{ __html: translate('print-page-check-website') }}></Text>
          </Texts>
          {timetableData && renderLine(component, line, true, { timetableData, timetableHours })}
          {canPrint && <div id="can-print"></div>}
        </>
      )}
    </Page>
  )
}

export default PrintTimetable

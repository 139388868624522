import { createBrowserHistory, createMemoryHistory } from 'history'

const history = process.env.REACT_APP_FORCE_BROWSER_HISTORY
  ? createBrowserHistory
  : process.env.REACT_APP_LIBRARY_URL
  ? createMemoryHistory
  : createBrowserHistory

export default history({
  keyLength: 10,
})

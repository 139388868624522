export default (
  state = {
    reduxMarkers: [],
    isLegendOpen: false,
    customLines: [],
    customMarkers: [],
    zoom: null,
    center: null,
    allLinesColorType: 'segmentation',
    allLinesIntersecGeojson: {},
    markerMode: 'default',
    territories: [],
    clusters: [],
    hideHeavyLines: false,
  },
  action,
) => {
  switch (action.type) {
    case 'SET_AREAS':
      return {
        ...state,
        reactAreas: action.areas,
      }

    case 'SET_STOPS':
      return {
        ...state,
        reactStops: action.stops,
      }

    case 'BUILD_TERRITORY_OUTLINE':
      return {
        ...state,
        territoryOutline: action.geojson,
      }

    case 'BUILD_ENTRANCE_MAP':
      return {
        ...state,
        entranceMap: action.geojson,
      }

    case 'SET_REDUX_MARKERS':
      return {
        ...state,
        reduxMarkers: action.markers,
      }

    case 'SET_HEAVY_LINES':
      return {
        ...state,
        heavyLines: action.lines,
      }

    case 'HIDE_HEAVY_LINES':
      return {
        ...state,
        hideHeavyLines: action.hide,
      }

    case 'SET_COMPLEMENTARY_LINE':
      return {
        ...state,
        complementaryLine: action.line,
      }

    case 'SET_COMPLEMENTARY_LINES':
      return {
        ...state,
        complementaryLines: action.lines,
      }

    case 'SET_CUSTOM_LINES':
      return {
        ...state,
        customLines: action.lines,
      }

    case 'ON_MARKER_CLICK':
      return {
        ...state,
        openedMarker: action.marker,
      }

    case 'SET_LINE_SELECTED':
      return {
        ...state,
        lineSelected: action.line,
      }

    case 'SET_LINE_SELECTED_PATH':
      return {
        ...state,
        selectedLine: action.path,
      }

    case 'SET_TRANSPORT_PLACES':
      return {
        ...state,
        transportPlaces: action.places,
      }

    case 'SET_MAP_PLACES':
      return {
        ...state,
        mapPlaces: action.places,
      }

    case 'SET_PUBLIC_PLACES':
      return {
        ...state,
        places: action.places,
      }

    case 'SET_CLUSTER':
      return {
        ...state,
        cluster: action.cluster,
        clusters: state.clusters.find(c => c._leaflet_id === action.cluster._leaflet_id)
          ? state.clusters
          : [...state.clusters, action.cluster],
      }

    case 'SET_ENTRANCE_MAP_MARKERS':
      return {
        ...state,
        entranceMapMarkers: action.markers,
      }

    case 'HANDLE_LEGEND_STATE':
      return {
        ...state,
        isLegendOpen: !action.isLegendOpen,
      }

    case 'SET_BIKE_PATHS':
      return {
        ...state,
        bikePaths: action.paths,
      }

    case 'SET_MAP_BIKES':
      return {
        ...state,
        bikes: action.bikes,
      }

    case 'SET_ENTRANCE_POPUP':
      return {
        ...state,
        entrancePopup: action.popup,
      }

    case 'SET_TOURISM_PARTNERS_STOPS':
      return {
        ...state,
        reactTourismPartnersStops: action.stops,
      }

    case 'SET_LINES_TO_DISPLAY':
      return {
        ...state,
        reactLines: action.polylines,
      }

    case 'SET_CUSTOM_MARKERS':
      return {
        ...state,
        customMarkers: action.markers,
      }

    case 'ADD_CUSTOM_MARKERS':
      return {
        ...state,
        customMarkers: [...state.customMarkers, ...action.markers],
      }

    case 'SET_CUSTOM_MARKERS_EVENT':
      return {
        ...state,
        customMarkers: state.customMarkers.map(m => ({
          ...m,
          lastEvent: m.key === 'customMarker-' + action.id ? action.event : m.lastEvent,
        })),
      }

    case 'SET_ZOOM':
      return {
        ...state,
        zoom: action.zoom,
      }

    case 'SET_CENTER':
      return {
        ...state,
        center: action.center,
      }

    case 'SET_AROUND_PIN':
      return {
        ...state,
        aroundPin: action.aroundPin,
        aroundCircles: action.aroundCircles,
      }

    case 'SET_DISPLAY_MARKER_MODE':
      return {
        ...state,
        markerMode: action.mode,
      }

    case 'SET_ALL_LINES_GEOJSON':
      return {
        ...state,
        allLinesGeojson: action.geojson,
      }

    case 'SET_ALL_LINESINTERSEC_GEOJSON':
      return {
        ...state,
        allLinesIntersecGeojson: action.geojson,
      }

    case 'SET_ALL_LINES_SELECTED':
      return {
        ...state,
        allLinesSelected: action.selected,
      }

    case 'SET_ALL_LINES_COLOR_TYPE':
      return {
        ...state,
        allLinesColorType: action.colorType,
      }

    case 'SET_TERRITORIES_GEOJSON':
      return { ...state, territories: action.territories }

    default:
      return state
  }
}

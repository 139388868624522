import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UILine } from './UILine'
import styled from 'styled-components'
import UIStopScheduleTime from './UIStopScheduleTime'
import UIStopPmr from './UIStopPmr'
import {
  assetsPath,
  getDisruptionsFromOneStop,
  handleKeyUp,
  isActiveModule,
  translate,
  updatePopupPosition,
} from '../../services/tools'
import { buildLinesLabels } from '../../services/map'
import { actionOnLineSelected } from '../../actions/withRedux'
import { message } from '../../services/message'
import { dateIsAtLeastTomorrow, navitiaDateToDate } from '../../utils/tools'
import exportedSCSS from '../../scss/app.scss'
import { useRouter } from '../../hooks/useRouter'
import axios from '../../middlewares/axios'
import { actionSetLinesSchedules } from '../../actions/board'
import UIDisruptedLineOverlay from './UIDisruptedLineOverlay'

const { REACT_APP_SCHEDULES_EXTENDS } = process.env
const { textColor, borderXS, secondarycolor } = exportedSCSS

const Lines = styled.div`
  cursor: default;
  color: ${() => textColor};
`

const Line = styled.div`
  display: flex;
  gap: 5px;
  &:not(:first-child) {
    padding-top: 10px;
    border-top: solid ${() => borderXS} 1px;
    margin-top: 5px;
  }
  &:last-child {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .lc-uiline {
    width: 30px !important;
    height: 30px !important;
    margin-top: 7px;
  }
`

const LineContent = styled.div`
  &:not(:first-child) {
    margin-top: 0px;
  }
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-radius: 5px;
  font-size: 0.9em;
  &:hover {
    background-color: ${props => (props.canClickLine ? secondarycolor : 'transparent')};
  }
  cursor: ${props => (props.canClickLine ? `pointer` : 'default !important')};
`

const LineDirection = styled.div`
  white-space: ${props => (!props.isMobile ? 'nowrap' : '')};
  display: flex;
  align-items: center;
  gap: 2.5px;
  font-weight: bold;
`

const LineSchedules = styled.div`
  display: flex;
  gap: ${props => (props.flexDirectionRow === true ? '5px' : '20px')};
  flex-direction: ${props => (props.flexDirectionRow === true ? 'column' : 'row')};
  margin-top: ${props => (props.marginTop ? `5px` : '0px')};
  white-space: ${props => (props.flexDirectionRow === true ? 'nowrap' : '')};
  .lc-schedule {
    img {
      width: 12px;
      margin-left: 5px;
    }
  }
`

const NoScheduleToday = styled.div`
  .lc-no-schedule-today {
    margin-top: 5px;
    margin-left: ${props => (props.marginLeft ? props.marginLeft : '0px')};
    ${props => (props.displayFlex ? `display: flex; align-items: flex-start;` : '')}
    img {
      width: 15px;
      ${props =>
        !props.displayFlex
          ? `margin-bottom: 2px;
      margin-bottom: ${props => (props.noMarginBottomImage ? '0px' : '2.5px')};`
          : ''}

      margin-right: 5px;
    }
  }
`

const MoreSchedules = styled.div`
  font-size: 0.8em;
  cursor: pointer;
  display: inline-block;
  margin: 0px 7px ${props => (props.marginBottom ? '7px' : '0px')} 7px;
  white-space: nowrap;
  text-decoration-line: underline;
`

const UIPopupLinesByStop = props => {
  const { stop, componentState, dataKey } = props
  const { configApp, language, component, lock, isMobile, areas, linesModes } = useSelector(state => state.app)
  const { linesSchedules, disruptionsInLine } = useSelector(state => state.board)
  const { openedMarker } = useSelector(state => state.map)
  const dispatch = useDispatch()
  const { options } = component.props
  const { stopsList, currentLine } = component.state
  const canClickLine = options?.features?.['change-line'] === false ? false : true
  const router = useRouter()
  const proximityLines = []

  const area = areas.find(a => a.id === stop.stop_area)

  if (area) {
    for (const l of area.lines) {
      if (!stop.lines.find(line => line.id === l.id)) {
        proximityLines.push(l)
      }
    }
  }

  const isTerminus =
    stopsList?.find(s => s.id === stop.id)?.terminus && stop.id === stopsList[stopsList?.length - 1]?.id

  useEffect(() => {
    setTimeout(() => {
      if (stop?.ref?.leafletElement) {
        updatePopupPosition(stop.ref.leafletElement, stop)
      }
    })
  }, [stop, linesSchedules])

  const getSchedules = () => {
    const params = { stop: stop.id, count: 99 }
    if (REACT_APP_SCHEDULES_EXTENDS) {
      params.extend = +REACT_APP_SCHEDULES_EXTENDS
    }
    axios({ url: '/api/schedules', params })
      .then(response => {
        if (response?.data?.length > 0) {
          const newSchedules = []
          for (const s of response.data) {
            for (const dt of s.date_times) {
              newSchedules.push({ ...dt, route_id: s.route_id })
            }
          }

          const respSchedules = []

          for (const route of response.data) {
            if (!stop.linesTerminus) {
              stop.linesTerminus = {}
            }
            stop.linesTerminus[route.route_id] = route.additional_informations === 'terminus'
            for (const schedule of route.date_times) {
              respSchedules.push({
                time: schedule.date_time,
                realtime: schedule.data_freshness === 'realtime',
                extend: process.env.REACT_APP_SCHEDULES_EXTENDS ? true : false,
                equipments: schedule.equipments ? schedule.equipments : [],
                destination: schedule.destination ? schedule.destination : false,
                route: schedule.route ? schedule.route : route.route_id ? route.route_id : undefined,
                stop_point: stop.id,
              })
            }
          }

          dispatch(actionSetLinesSchedules(respSchedules))
        }
      })
      .catch(e => {
        console.log('Error in get schedules : ', e)
      })
  }

  useEffect(() => {
    if (
      (isActiveModule('around') || isActiveModule('route-calculation')) &&
      openedMarker?.id === stop.id &&
      stop.lines?.length <= 2
    ) {
      getSchedules()
    }
    // eslint-disable-next-line
  }, [openedMarker, area])

  const handleClickLine = (e, line, data) => {
    e.stopPropagation()

    if (
      line &&
      !lock &&
      canClickLine &&
      (router.query.current
        ? router.query.current !== `${line.id}_${line.direction_id}`
        : router.query.line !== `${line.id}_${line.direction_id}`)
    ) {
      message({ clicked: 'line', id: line.id })
      dispatch(actionOnLineSelected(line, data))
    }
  }

  const handleClickSeeSchedule = (e, type) => {
    let from = ''
    if (isActiveModule('around')) {
      from = '&from=around' + (router.query.line ? `&fromline=${router.query.line}` : '')
    } else if (isActiveModule('route-calculation')) {
      from = '&from=route-calculation'
    } else if (router.pathname === '/') {
      from = '&from=entrance'
    } else if (isActiveModule('lines') && router.query.stop && !router.query.current) {
      from = `&from=${router.query.stop}`
    } else if (isActiveModule('lines') && router.query.current) {
      from = `&from=${router.query.current}`
    }
    if (type === 'area') {
      router.push(`/lines?stop_area=${stop.stop_area}${from}`)
    }
    if (type === 'stop') {
      router.push(`/lines?stop=${stop.id}${from}`)
    }
    e.stopPropagation()
    e.preventDefault()
    return
  }

  if (stop.lines?.length > 2 || stop.id !== openedMarker?.id || isTerminus) {
    let hasLineSelected = false
    if (router.query.current || router.query.line) {
      const lineId = router.query.current || router.query.line
      hasLineSelected = stop.lines.find(l =>
        lineId.includes('_f')
          ? l.id === lineId?.split('_f')[0]
          : lineId?.includes('_b')
          ? l.id === lineId.split('_b')[0]
          : l.id === lineId,
      )
    }

    return (
      <>
        {stop.id === openedMarker?.id &&
        isTerminus !== true &&
        linesSchedules?.filter(ls => ls.stop_point === stop?.id)?.length > 0 &&
        linesSchedules?.filter(ls =>
          currentLine
            ? currentLine?.routes?.find(route => route.direction_id === currentLine.direction_id)?.route_id ===
                ls.route &&
              ls.stop_point === stop?.id &&
              !dateIsAtLeastTomorrow(navitiaDateToDate(ls.time))
            : ls.stop_point === stop?.id && !dateIsAtLeastTomorrow(navitiaDateToDate(ls.time)),
        ).length === 0 ? (
          <NoScheduleToday marginLeft="7.5px" noMarginBottomImage={true}>
            <div className="lc-no-schedule-today" tabIndex="0" role="button">
              <img src={assetsPath('/assets/images/informations.svg')} alt="information" aria-hidden="true" />
              {hasLineSelected
                ? translate('no-schedule-today-for-line', false, {
                    key: 'code',
                    value: hasLineSelected?.code,
                  })
                : translate('no-schedule-today')}
            </div>
          </NoScheduleToday>
        ) : null}
        {buildLinesLabels(componentState, stop, dataKey, {
          buttonSeeMoreSchedulesStopArea: (
            <MoreSchedules onClick={e => handleClickSeeSchedule(e, 'area')} marginBottom={true}>
              {translate('popup-schedules-lines-see-more-schedules-area')}
            </MoreSchedules>
          ),
          buttonSeeMoreSchedulesStopPoint: (
            <MoreSchedules onClick={e => handleClickSeeSchedule(e, 'stop')} marginBottom={true}>
              {translate('popup-schedules-lines-see-more-schedules-stop')}
            </MoreSchedules>
          ),
        })}
      </>
    )
  }

  return (
    <Lines>
      {stop?.lines
        ?.filter(line => {
          for (const lm of linesModes) {
            if (lm.modes.includes(line.mode)) {
              return true
            }
          }
          return false
        })
        ?.map(line => {
          const multiDestinations = linesSchedules
            ?.filter(ls => ls.route === line.route_id && ls.stop_point === stop.id)
            .map(ls => ls.destination)
            .filter((item, index, arr) => arr.indexOf(item) === index)

          if (multiDestinations.length > 1) {
            return multiDestinations.map(d => {
              return (
                <Line key={line.id + line.route_id + '' + d}>
                  <div className="">
                    <UIDisruptedLineOverlay line={line} styleLine={process.env.REACT_APP_LINES_MAIN_TYPE}>
                      <UILine line={line} image={process.env.REACT_APP_LINES_MAIN_TYPE?.includes('image')} />
                    </UIDisruptedLineOverlay>
                  </div>
                  <LineContent
                    onClick={e => handleClickLine(e, line, stop)}
                    onKeyUp={e => handleKeyUp(e, () => handleClickLine(e, line, stop))}
                    role={canClickLine ? 'button' : 'img'}
                    tabIndex="0"
                    aria-label={translate('aria-line', false, { key: 'code', value: 'line.code' })}
                    canClickLine={
                      canClickLine && router.query.current
                        ? router.query.current !== `${line.id}_${line.direction_id}`
                        : router.query.line !== `${line.id}_${line.direction_id}`
                    }>
                    <LineDirection isMobile={isMobile}>
                      {translate('schedules-to')} {d ? d : line.direction}
                      <UIStopPmr pmr={stop?.pmr} />
                    </LineDirection>
                    <LineSchedules>
                      {configApp?.no_schedule_today_label !== true &&
                      linesSchedules.filter(
                        ls => ls.route === line.route_id && ls.stop_point === stop.id && ls.destination === d,
                      ).length === 0 ? (
                        <div>{translate('no-schedules')}</div>
                      ) : configApp?.no_schedule_today_label === true &&
                        linesSchedules
                          .filter(ls => ls.route === line.route_id && ls.stop_point === stop.id && ls.destination === d)
                          ?.filter(
                            s => s && s.time && new Date(navitiaDateToDate(s.time)).getDate() === new Date().getDate(),
                          ).length === 0 ? (
                        <NoScheduleToday displayFlex={true}>
                          <div className="lc-no-schedule-today" tabIndex="0" role="button">
                            <img
                              src={assetsPath('/assets/images/informations.svg')}
                              alt="information"
                              aria-hidden="true"
                            />
                            {translate('no-schedule-today-for-line', false, {
                              key: 'code',
                              value: line?.code,
                            })}
                          </div>
                        </NoScheduleToday>
                      ) : null}
                    </LineSchedules>
                    {disruptionsInLine?.length > 0 &&
                    component?.state?.currentLine?.id === line.id &&
                    getDisruptionsFromOneStop(disruptionsInLine, stop, component?.state?.currentLine)?.filter(
                      d => d.severity === 'blocking',
                    )?.length > 0 ? (
                      <div>{translate('no-schedules')}</div>
                    ) : (
                      <LineSchedules
                        marginTop={true}
                        flexDirectionRow={
                          configApp?.no_schedule_today_label === true &&
                          linesSchedules
                            .filter(
                              ls => ls.route === line.route_id && ls.stop_point === stop.id && ls.destination === d,
                            )
                            ?.filter(
                              s =>
                                s && s.time && new Date(navitiaDateToDate(s.time)).getDate() === new Date().getDate(),
                            ).length === 0
                        }>
                        {linesSchedules
                          ?.filter(
                            ls => ls.route === line.route_id && ls.stop_point === stop.id && ls.destination === d,
                          )
                          ?.filter((_, sindex) => sindex < 2)
                          ?.map((schedule, sindex) => (
                            <UIStopScheduleTime
                              key={`schedule-${sindex}`}
                              configApp={configApp}
                              language={language}
                              schedule={schedule}
                            />
                          ))}
                      </LineSchedules>
                    )}
                  </LineContent>
                </Line>
              )
            })
          } else {
            return (
              <Line key={line.id + line.route_id}>
                <div className="">
                  <UIDisruptedLineOverlay line={line} styleLine={process.env.REACT_APP_LINES_MAIN_TYPE}>
                    <UILine line={line} image={process.env.REACT_APP_LINES_MAIN_TYPE?.includes('image')} />
                  </UIDisruptedLineOverlay>
                </div>
                <LineContent
                  onClick={e => handleClickLine(e, line, stop)}
                  onKeyUp={e => handleKeyUp(e, () => handleClickLine(e, line, stop))}
                  role={canClickLine ? 'button' : 'img'}
                  tabIndex="0"
                  aria-label={translate('aria-line', false, { key: 'code', value: 'line.code' })}
                  canClickLine={
                    canClickLine && router.query.current
                      ? router.query.current !== `${line.id}_${line.direction_id}`
                      : router.query.line !== `${line.id}_${line.direction_id}`
                  }>
                  <LineDirection isMobile={isMobile}>
                    {translate('schedules-to')} {line.direction}
                    <UIStopPmr pmr={stop?.pmr} />
                  </LineDirection>
                  <LineSchedules>
                    {configApp?.no_schedule_today_label !== true &&
                    linesSchedules.filter(ls => ls.route === line.route_id && ls.stop_point === stop.id).length ===
                      0 ? (
                      <div>{translate('no-schedules')}</div>
                    ) : configApp?.no_schedule_today_label === true &&
                      stop.linesTerminus &&
                      stop.linesTerminus[line.route_id] !== true &&
                      linesSchedules
                        .filter(ls => ls.route === line.route_id && ls.stop_point === stop.id)
                        ?.filter(
                          s => s && s.time && new Date(navitiaDateToDate(s.time)).getDate() === new Date().getDate(),
                        ).length === 0 ? (
                      <NoScheduleToday displayFlex={true}>
                        <div className="lc-no-schedule-today" tabIndex="0" role="button">
                          <img
                            src={assetsPath('/assets/images/informations.svg')}
                            alt="information"
                            aria-hidden="true"
                          />
                          {translate('no-schedule-today-for-line', false, {
                            key: 'code',
                            value: line?.code,
                          })}
                        </div>
                      </NoScheduleToday>
                    ) : configApp?.no_schedule_today_label === true &&
                      stop.linesTerminus &&
                      stop.linesTerminus[line.route_id] === true &&
                      linesSchedules
                        .filter(ls => ls.route === line.route_id && ls.stop_point === stop.id)
                        ?.filter(
                          s => s && s.time && new Date(navitiaDateToDate(s.time)).getDate() === new Date().getDate(),
                        ).length === 0 ? (
                      <div>{translate('stop-terminus')}</div>
                    ) : null}
                  </LineSchedules>
                  {disruptionsInLine?.length > 0 &&
                  component?.state?.currentLine?.id === line.id &&
                  getDisruptionsFromOneStop(disruptionsInLine, stop, component?.state?.currentLine)?.filter(
                    d => d.severity === 'blocking',
                  )?.length > 0 ? (
                    <div>{translate('no-schedules')}</div>
                  ) : (
                    <LineSchedules
                      marginTop={true}
                      flexDirectionRow={
                        configApp?.no_schedule_today_label === true &&
                        linesSchedules
                          .filter(ls => ls.route === line.route_id && ls.stop_point === stop.id)
                          ?.filter(
                            s => s && s.time && new Date(navitiaDateToDate(s.time)).getDate() === new Date().getDate(),
                          ).length === 0
                      }>
                      {linesSchedules
                        ?.filter(ls => ls.route === line.route_id && ls.stop_point === stop.id)
                        ?.filter((_, sindex) => sindex < 2)
                        ?.map((schedule, sindex) => (
                          <UIStopScheduleTime
                            key={`schedule-${sindex}`}
                            configApp={configApp}
                            language={language}
                            schedule={schedule}
                          />
                        ))}
                    </LineSchedules>
                  )}
                </LineContent>
              </Line>
            )
          }
        })}
      {isActiveModule('lines') && router.query.stop !== stop.id ? null : (
        <MoreSchedules onClick={e => handleClickSeeSchedule(e, 'stop')} marginBottom={proximityLines?.length > 0}>
          {translate('popup-schedules-lines-see-more-schedules-stop')}
        </MoreSchedules>
      )}
      {buildLinesLabels(componentState, stop, dataKey, { onlyProximityLines: true })}
      {isActiveModule('lines') && router.query.stop !== stop.id ? null : proximityLines?.length > 0 ? (
        <MoreSchedules onClick={e => handleClickSeeSchedule(e, 'area')}>
          {translate('popup-schedules-lines-see-more-schedules-area')}
        </MoreSchedules>
      ) : null}
    </Lines>
  )
}

export default UIPopupLinesByStop
